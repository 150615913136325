// @ts-nocheck
import { useState } from "react";
import {
  Create,
  Form,
  Input,
  Select,
  useSelect,
  IResourceComponentsProps,
  useForm,
  useTranslate,
  Button,
  Checkbox,
  Upload,
  DatePicker,
  Table,
} from "@pankod/refine";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import { fieldLable } from "./index";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IVenue,ISubject, ICoupon, IStoreItem, IProduct } from "interfaces";

import passGen from 'generate-password'
import { toPercent, useManyResource } from "utils";
import RichText from "components/RichText";


const dateFormat = 'YYYY-MM-DD'
 
  
  




//console.log( moment.toLoc(record?.startDate));

const timeFormat = 'h:mm a';



{/* <Select options={visibilityOptions} /> */}



export const CourseCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult } = useForm<ICourse>();

  const record = queryResult?.data?.data;


  

  

  const dateFormat = 'YYYY-MM-DD'
 
  
  

  const defaultStartDate = record?.startDate ? moment(record.startDate) : null
  const defaultEndDate = record?.startDate ? moment(record.endDate) : null
  
  
  //console.log( moment.toLoc(record?.startDate));
  
const timeFormat = 'h:mm a';



{/* <Select options={visibilityOptions} /> */}

  const subjectsOprions = useManyResource({resource:'academy/subject',map: ({name,_id}) => ({label:name,value:_id})})
  const instructorsOprions = useManyResource({resource:'academy/instructor',map: ({name,_id}) => ({label:name,value:_id})})
  const organizersOption = useManyResource({resource:'user',map: ({name,_id}) => ({label:name,value:_id}),filter: u => u.role === 'organizer'})

  
  const instituteOprions = useManyResource({resource:'academy/institute',map: ({name,_id}) => ({label:name,value:_id})})

  const formValues = formProps.form?.getFieldsValue()
  const venus = useManyResource({resource:'location/venue' })

  

  
  //const venueOprions = venus?.filter(v => v.city === currentCity ).map(v => ({label:v.name,value:v._id}))
  const venueOprions = venus?.map(v => ({label:v.name,value:v._id}))
  const { selectProps: citySelectProps } = useSelect<ICity>({
    resource: "location/city",
    defaultValue: queryResult?.data?.data.city,
    optionLabel: "name",
    optionValue: "_id",
    
    
  });






function createFromData(values: any) {

  const data = new FormData();

  

  console.log(values.date)

  const {image,venue,date,...rest} = values

  image &&  data.append('image', image.file);
  const city = venus?.find(v => v._id === venue)?.city ?? null
  const jsonData = JSON.stringify({...rest, city});

  data.append('json',jsonData)

  
   
//  if (venue) {
//   data.append('venue', venue);
//   const cityId = venus?.find(v => v._id === venue).city
//   data.append('city', cityId);
  
// }

//  if (date) {
  
//   const sessionsDate = values.date.map(d =>d.$d)
  
//   const [start,end] = sessionsDate
//   console.log(start,end)
//   data.append('startDate', start);
//   data.append('endDate', end);

//   //const cityId = venus?.find(v => v._id === values[key]).city
//  // data.append('city', cityId);
  
// }

return data


}






  

  const visibilityOptions = [
    { label: "Draft", value: "draft" },
    { label: "Public", value: "public" },
  ];

  const availableOptions = [
    { label: "Pre sell", value: "pre-sell" },
    { label: "Available", value: "available" },
    { label: "Unavailable", value: "not-available" },
    { label: "Solid Out", value: "solid-out" },
  ];

  

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form
        {...formProps}
        layout="vertical"
        onFinish={async (values: any) => {
          const data = createFromData(values);

         
          return formProps.onFinish && formProps.onFinish(data);
        }}
      >
        <Form.Item>
          <Form.Item name="image" noStyle>
            <Upload.Dragger
              name="image"
            
              accept="image/png, image/jpeg"
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item
          label={t(fieldLable("title"))}
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>


        <Form.Item
          label='Hashtag'
          name="hashtag"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Twitter Hashtag Url'
          name="twitterTagUrl"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Instagram Hashtag Url'
          name="instagramTagUrl"
        >
          <Input />
        </Form.Item>

        

        {/* <Form.Item
          label={t(fieldLable("startTime"))}
          name="startTime"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TimePicker use12Hours format= {timeFormat} />
          
        </Form.Item> */}

        {/* <Form.Item
          label={t(fieldLable("endTime"))}
          name="endTime"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TimePicker use12Hours format= {timeFormat} />
          
        </Form.Item> */}

<Form.Item label={'Group'} name="group">
          <Input  />
        </Form.Item>


        <Form.Item label={t(fieldLable("price"))} name="price">
          <Input type="number" />
        </Form.Item>

        <Form.Item name="featured">
          <FromCheckBox label={t(fieldLable("featured"))} />
        </Form.Item>


        <Form.Item name="online">
          <FromCheckBox label={t(fieldLable("online"))} />
        </Form.Item>

        <Form.Item label={t(fieldLable("url"))} name="url">
          <Input  />
        </Form.Item>

        <Form.Item
          label={t(fieldLable("subjects"))}
          name="subjects"
          
        >
          <Select options={subjectsOprions} mode="multiple"   />
        </Form.Item>

        <Form.Item
          label={t(fieldLable("instructors"))}
          name="instructors"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={instructorsOprions} mode="multiple"   />
        </Form.Item>

        <Form.Item
          label={'Institute'}
          name="institute"
          
        >
          <Select options={instituteOprions}    />
        </Form.Item>

        <Form.Item
          label={t(fieldLable("city"))}
          name="city"
         
        >
          <Select {...citySelectProps} />
        </Form.Item>

        <Form.Item
          label={t(fieldLable("venue"))}
          name="venue"
         
        >
          <Select options={venueOprions} />
        </Form.Item>

        <Form.Item
          label= 'Location Note'
          name="locationNote"
         
        >
          <Input  />
        </Form.Item>


        <Form.Item
          label={t(fieldLable("organizers"))}
          name="organizers"
          
        >
          <Select options={organizersOption} mode="multiple" />
        </Form.Item>

        
        
        <Form.Item label={t("product.fields.description")} name="description">
          <RichText edit />
        </Form.Item>

        <Form.Item label={t("Certificate Requirement")} name="certificateRequirement">
          <RichText edit />
        </Form.Item>

        <Form.Item label={t("Material And Kit")} name="materialAndKit">
          <RichText edit />
        </Form.Item>

        <Form.Item label={t("Prerequisites")} name="prerequisites">
          <RichText edit />
        </Form.Item>



        <Form.Item label='Terms' name="terms">
          <RichText edit />
        </Form.Item>


        <Form.Item
          label={t(fieldLable("visibility"))}
          name="visibility"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={visibilityOptions}  />
        </Form.Item>

        <Form.Item
          label={t(fieldLable("available"))}
          name="available"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={availableOptions} />
        </Form.Item>

        <Form.Item
          label={'Days'}
          name="sessions"
          
        >
          <SessionTable/>
        </Form.Item>

        
      </Form>
    </Create>
  );
};

const SessionTable = ({value = [],onChange = i => []}) => {


  const addSession = () => {

    const lastSession:any = value.length > 0 ? value[value.length - 1] : null


    const session = {
      start: lastSession ? addDay(lastSession.start) : null,
      end:null,
      length: lastSession?.length ?? 60,
      checkIn:[],
      checkOut:[]
    }

    

    onChange([...value,session])
  }

  const updateRecourd = (record,prop,val) => {

    console.log('run')

    
    const updatedSessions = [...value]

    const currentSession:any = updatedSessions[record.number-1]

    console.log(currentSession,val)
    
    currentSession[prop] = prop === 'length' ?  parseFloat(val) * 60 : val

    
    onChange(updatedSessions)


  }

  const removeRecord = (index) => {

    const updatedSessions = [...value]
    updatedSessions.splice(index, 1)

    onChange(updatedSessions)


  }

  const columns = [
    {
      title:'Day Number',
      dataIndex:'number',
     
    
    }
    ,{title:'Date',dataIndex:'start',render: (d,record) => <Input type="datetime-local"  onChange={e => updateRecourd(record,'start',e.target.value)} defaultValue = {d?.substring(0,16)}/>},
    {
      title:'Duration (hours)',
      dataIndex:'length',
      render: (l,record) => <Input defaultValue={l/60} type="number" onKeyUp={e => updateRecourd(record,'length',e.target.value)}  />
     
    
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_,record) => <a onClick = {()=> removeRecord(record.number-1)}>Delete</a>,
    },
  ]

  const sessions = value?.map((s:any,i) => ({number:i+1,...s}))

return (
  <>
  <Button onClick={addSession} >Add Day</Button>
  <Table dataSource={sessions} columns={columns} />
  </>

)

}

const FromCheckBox = ({label='',value=false,onChange = boo => Boolean}) => {

  return ( <Checkbox checked={value} onChange={e => onChange(e.target.checked)}>{label}</Checkbox>)

}

function addDay(dateString,days = 1) {
  const date = new Date(dateString);
  date.setDate(date.getDate() + days)
  return date.toISOString()
}

