import {
  Show,
  useShow,
  Typography,
  Tag,
  useOne,
  IResourceComponentsProps,
  MarkdownField,
  useTranslate,
  useMany,
  TagField,
  Image,
} from "@pankod/refine";
import RichText from "components/RichText";

import { ICity, ICountry,IInstitute,IOrganization,ISubject } from "interfaces";

const { Title, Text } = Typography;

export const OrganizationShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<IOrganization>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

//     const countryQueryResult = useOne<ICountry>({
//     resource: "location/countory",
//     id: record?.subjects + '',
//     queryOptions: {
//       enabled: !!record?.country,
//   },
// });

const countryQueryResult = useOne<ICity>({
  resource: "location/city",
  id: record?.city + '',
  queryOptions: {
    enabled: !!record?.city,
},
});
  


// const toSet = new Set(countryIds)
// const { data: countryData, isLoading } = useMany<ICountry>({
// resource: "location/countory",
// ids: Array.from(toSet),
// queryOptions: {
//   enabled: countryIds.length > 0,
// },
// });

// const { selectProps: countrySelectProps } = useSelect<ICountry>({
// resource: "location/countory",
// });

  

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t("institute.fields.name")}</Title>
      <Text>{capitalize(record?.name ?? '')}</Text>

      <Title level={5}>Sector</Title>
      <Text>{capitalize(record?.sector ?? '')}</Text>

      <Title level={5}>Industry</Title>
      <Text>{capitalize(record?.industry ?? '')}</Text>

      <Title level={5}>{t("venue.fields.city")}</Title>
      
      <Tag>{countryQueryResult?.data?.data.name}</Tag>


        
              <Title level={5}>{t("institute.fields.description")}</Title>
              <RichText value = {record?.description}  />
    </Show>
  );
};

function capitalize(text: string) {
  return text.replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
}
