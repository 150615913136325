import { useState } from "react";
import {
  Create,
  Form,
  Input,
  Select,
  useSelect,
  IResourceComponentsProps,
  useForm,
  useTranslate,
  Button,
  Checkbox,
} from "@pankod/refine";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";
import { fieldLable } from "./index";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IVenue,ISubject, ICoupon } from "interfaces";

import passGen from 'generate-password'
import { toPercent } from "utils";

const codeGen = () => passGen.generate({
  length: 7,
numbers: true
})


export const CouponCreate: React.FC<IResourceComponentsProps> = () => {
  

  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult } = useForm<ICoupon>();

  

  return (
    <Create saveButtonProps={saveButtonProps}>
       <Form {...formProps} layout="vertical"
      onFinish={async (values:any) => {
        const {discountType} = values
        const discountAmount = discountType === 'fix' ? values.discountAmount : toPercent(values.discountAmount) 

        return formProps.onFinish && formProps.onFinish({...values,discountAmount})

      }}
      >
        
        <Form.Item
          label={t(fieldLable('name'))}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t(fieldLable('code'))}
          name="code"
          rules={[
            {
              required: true,
            },
          ]}
        >
         <CodeGen/>
        </Form.Item>

        <Form.Item
          label={t(fieldLable('kind'))}
          name="kind"
          rules={[
            {
              required: true,
            },
          ]}
        >
         <Select options={[{lable:'cart',value:'cart'},{lable:'product',value:'product'}]}/>
        </Form.Item>

        <Form.Item
          label={t(fieldLable('discountType'))}
          name="discountType"
          rules={[
            {
              required: true,
            },
          ]}
        >
         <Select options={[{lable:'fix',value:'fix'},{lable:'percent',value:'percent'}]}/>
        </Form.Item>

        <Form.Item
          label={t(fieldLable('discountAmount'))}
          name="discountAmount"
        
          rules={[
            {
              required: true,
            },
          ]}
        >
         
         <Input />
        </Form.Item>

        <Form.Item
          
          name="publish"
          
        >
         
         <FromCheckBox label={t(fieldLable('active'))}/>
        </Form.Item>

        <Form.Item
          label={t(fieldLable('limt'))}
          name="limt"
          
        >
         
         <Input type="number" />
        </Form.Item>



      
          
      </Form>
    </Create>
  );
};

const CodeGen = ({value='',onChange= code =>''}) => {

  return (
    <>
    <Button style = {{marginBottom: 8}} onClick={() =>onChange(codeGen())}>generate new code</Button>
     <Input value={value} onChange={onChange} />
    </>
  )

}

const FromCheckBox = ({label='',value=false,onChange = boo => Boolean}) => {

  return ( <Checkbox value = {value} onChange={e => onChange(e.target.checked)}>{label}</Checkbox>)

}

