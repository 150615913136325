import { Authenticated, Refine, TextField } from "@pankod/refine";
import routerProvider from "@pankod/refine-react-router";

import "@pankod/refine/dist/styles.min.css";
import simpleRestDataProvider from "@pankod/refine-simple-rest";
import { authProvider } from "authProvider";

import { CountryList, CountryShow ,CountryEdit, CountryCreate} from "pages/country";


import {
  Title,
  Header,
  Sider,
  Footer,
  Layout,
  OffLayoutArea,
} from "components/layout";
import { useTranslation } from "react-i18next";
import { CityCreate, CityEdit, CityList, CityShow } from "pages/city";
import { VenueCreate, VenueEdit, VenueList, VenueShow } from "pages/venue";
import { SubjectCreate, SubjectEdit, SubjectList, SubjectShow } from "pages/subject";
import { InstituteCreate, InstituteEdit, InstituteList, InstituteShow } from "pages/institute";

import { InstructorCreate, InstructorEdit, InstructorList, InstructorShow } from "pages/instructor";
import { UserCreate, UserEdit, UserList, UserShow } from "pages/user";
import { CouponCreate, CouponEdit, CouponList, CouponShow } from "pages/coupon";
import { StoreItemCreate, StoreItemEdit, StoreItemList, StoreItemShow } from "pages/product";
import { CourseCreate, CourseEdit, CourseList, CourseShow } from "pages/course";

import axios from "@pankod/refine-simple-rest/node_modules/axios";
import { OrderCreate, OrderEdit, OrderList, OrderShow } from "pages/order";
import { EnrollEdit, EnrollList, EnrollShow } from "pages/enroll";
import { Login } from "pages/login";




import 'style.css'
import { ShopFilled } from "@ant-design/icons";
import { Dashboard } from "pages/dashbord";
import { RefundEdit, RefundList, RefundShow } from "pages/refund";
import { OrganizationCreate, OrganizationEdit, OrganizationList, OrganizationShow } from "pages/organization";


const TOKEN_KEY = "refine-auth";
function authHeader() {
  const token =  localStorage.getItem(TOKEN_KEY); 
const  headers =  {
  'Authorization': "Bearer " + token,
}
return headers
}

const API_URL : string = process.env.REACT_APP_API_URL ?? ''

//const baseURL = 'http://localhost:8000/api' //process.env['REACT_APP_API_UR']



// https://api.fake-rest.refine.dev"

const AuthenticatedDashboard = () => {
  return (
      <Authenticated>
          <Dashboard />
      </Authenticated>
  );
};


function App() {
  const { t, i18n } = useTranslation();

  
  const httpClient = axios.create({headers:authHeader()})

  const dataProvider = simpleRestDataProvider(API_URL,httpClient);

  const i18nProvider = {
    translate: (key: string, params: object) => t(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  const resources = [

    {
      name: "store/product",
      options: {label:'Product'},
      list: StoreItemList,
      create: StoreItemCreate ,
       edit: StoreItemEdit,
       show: StoreItemShow,
       icon: <i className="fas fa-tag"></i>
    },
    {
      name: "store/course",
      options: {label:'Course'},
      list: CourseList,
      create: CourseCreate ,
       edit: CourseEdit,
       show: CourseShow,
       icon: <i className="fas fa-chalkboard-teacher"></i>
    },
    {
      name: "store/order",
      options: {label:'Order'},
      list: OrderList,
       edit: OrderEdit,
       show: OrderShow,
       icon: <i className="fas fa-file-invoice-dollar"></i>
    },
    {
      name: "store/refund",
      options: {label:'Refund Request'},
      list: RefundList,
       edit: RefundEdit,
       show: RefundShow,
       icon: <i className="fas fa-file-invoice-dollar"></i>
    },
    
    {
      name: "store/coupon",
      options: {label:'Coupon'},
      list: CouponList,
      create: CouponCreate ,
       edit: CouponEdit,
       show: CouponShow,
       icon:<i className="fas fa-ticket-alt"></i>
       
    },

    {
      name: "academy/enroll",
      options: {label:'Enroll'},
      list: EnrollList,
      //  edit: EnrollEdit,
      //  show: EnrollShow,
      icon:<i className="fas fa-user-graduate"></i>
    },

    {
      name: "academy/instructor",
      options: {label:'Instructor'},
      list: InstructorList,
      create: InstructorCreate ,
       edit: InstructorEdit,
       show: InstructorShow,
       icon:<i className="fas fa-user-tie"></i>
    },
    {
      name: "academy/institute",
      options: {label:'Institute'},
      list: InstituteList,
      create: InstituteCreate ,
       edit: InstituteEdit,
       show: InstituteShow,
       icon: <i className="fas fa-school"></i>
      
    },

    {
      name: "organization",
      options: {label:'Organization'},
      list: OrganizationList,
      create: OrganizationCreate ,
       edit: OrganizationEdit,
       show: OrganizationShow,
       icon: <i className="fas fa-school"></i>
      
    },

    {
      name: "location/venue",
      options: {label:'Venue'},
      list: VenueList,
      create:VenueCreate ,
       edit: VenueEdit,
       show: VenueShow,
       icon:<i className="fas fa-building"></i>
    },

    {
      name: "location/countory",
      options: {label:'Country'},
      list: CountryList,
      create: CountryCreate,
       edit: CountryEdit,
       show: CountryShow,
       icon:<i className="fas fa-flag"></i>
    },
    {
      name: "location/city",
      
      options: {label:'City'},
      list: CityList,
      create: CityCreate,
       edit: CityEdit,
       show: CityShow,
       icon:<i className="fas fa-city"></i>
    },
   
    {
      name: "academy/subject",
      options: {label:'Speciality'},
      list: SubjectList,
      create: SubjectCreate ,
       edit: SubjectEdit,
       show: SubjectShow,
       icon:<i className="fas fa-layer-group"></i>
    },
    
    {
      name: "user",
      options: {label:'User'},
      list: UserList,
      create: UserCreate ,
       edit: UserEdit,
       show: UserShow,
       icon:<i className="fas fa-users"></i>
    },
    
    
   
  ]

  return (
    <Refine

    DashboardPage = {Dashboard}

      routerProvider={{
                ...routerProvider,
                
            }}
      dataProvider={dataProvider}
       authProvider={authProvider}
      resources={resources}
      Title={Title}
      Header={Header}
      Sider={Sider}
      Footer={Footer}
      Layout={Layout}
      OffLayoutArea={OffLayoutArea}
      i18nProvider={i18nProvider}
      LoginPage={Login}
    ></Refine>
  );
}

export default App;
