import {
  Show,
  useShow,
  Typography,
  Tag,
  useOne,
  IResourceComponentsProps,
  MarkdownField,
  useTranslate,
  useMany,
  TagField,
  Image,
} from "@pankod/refine";
import RichText from "components/RichText";

import { ICity, ICountry,IInstitute,ISubject } from "interfaces";

const { Title, Text } = Typography;

export const InstituteShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<IInstitute>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

//     const countryQueryResult = useOne<ICountry>({
//     resource: "location/countory",
//     id: record?.subjects + '',
//     queryOptions: {
//       enabled: !!record?.country,
//   },
// });

const subjectIds =
record?.subjects?.map((item) => item._id) ?? []

    
    const toSet = new Set(subjectIds)
  const { data: subjectData} = useMany<ISubject>({
    resource: "academy/subject",
    ids: Array.from(toSet),
    queryOptions: {
      enabled: subjectIds.length > 0,
    },
  });

  const getSubjects = (sub:any) => {
    return subjectData?.data.filter(s => sub.includes(s._id))
     .map(s => s.name)
   }


// const toSet = new Set(countryIds)
// const { data: countryData, isLoading } = useMany<ICountry>({
// resource: "location/countory",
// ids: Array.from(toSet),
// queryOptions: {
//   enabled: countryIds.length > 0,
// },
// });

// const { selectProps: countrySelectProps } = useSelect<ICountry>({
// resource: "location/countory",
// });

  

  return (
    <Show isLoading={isLoading}>
 <Title level={5}>{t("institute.fields.logo")}</Title>
<Image src = {record?.logo} />
      <Title level={5}>{t("institute.fields.name")}</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>{t("institute.fields.subjects")}</Title>
      
      <div>
 {getSubjects(record?.subjects)?.map(s => <TagField value = {s}/>)}
              </div>
        
              <Title level={5}>{t("institute.fields.description")}</Title>
              <RichText value = {record?.description}  />
    </Show>
  );
};
