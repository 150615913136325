import {
  Show,
  useShow,
  Typography,
  Tag,
  useOne,
  IResourceComponentsProps,
  MarkdownField,
  useTranslate,
  BooleanField,
  ImageField,
  NumberField,
  Checkbox,
  TagField,
} from "@pankod/refine";
import { fieldLable } from "./index";
import { IVenue, ISubject, ICoupon, IStoreItem, IProduct } from "interfaces";
import RichText from "components/RichText";

const { Title, Text } = Typography;

export const StoreItemShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<IProduct>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  // _id: string;
  // title: string;
  // description: string;
  // createDate: date;
  // image: string;
  // media: string[],
  // featured: boolean;
  // price: number;
  // visibility: string;
  // available: string;
  // hasShipping: boolean;
  // shippingPrice: number;
  // hasDiscount: boolean;
  // discountType: string;
  // discountAmount: number;
  // discountStartDate: date;
  // discountEndDate: date;

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t(fieldLable("image"))}</Title>
      <ImageField value={record?.image} />
      <Title level={5}>{t(fieldLable("name"))}</Title>
      <Text>{record?.title}</Text>

      <Title level={5}>{t(fieldLable("price"))}</Title>
      <NumberField value={record?.price ?? 0} />

      <Title level={5}>
        <Checkbox checked={record?.featured}>
          {t(fieldLable("featured"))}
        </Checkbox>
      </Title>

      <Title level={5}>{t(fieldLable("description"))}</Title>
      <RichText value={record?.description} />

      <Title level={5}>{t(fieldLable("visibility"))}</Title>
      <TagField value={record?.visibility} />

      <Title level={5}>{t(fieldLable("available"))}</Title>
      <TagField value={record?.available} />

      <Title level={5}>{t(fieldLable("hasShipping"))}</Title>
      <BooleanField value={record?.hasShipping}></BooleanField>

      {/* <Title level={5}>{t(fieldLable('code'))}</Title>
      <Text>{record?.code}</Text>

      <Title level={5}>{t(fieldLable('kind'))}</Title>
      <Text>{record?.kind}</Text>

      <Title level={5}>{t(fieldLable('discountType'))}</Title>
      <Text>{record?.discountType}</Text>

      <Title level={5}>{t(fieldLable('discountAmount'))}</Title>
      <Text>{record?.discountType === 'fix' ? record?.discountAmount : `%${(record?.discountAmount ?? 0) * 100}`}</Text>

      <Title level={5}>{t(fieldLable('active'))}</Title>
      <BooleanField value={record?.publish}/>

      <Title level={5}>{t(fieldLable('limt'))}</Title>
      <Text>{record?.limt ?? 'unlimited'}</Text>

      <Title level={5}>{t(fieldLable('used'))}</Title>
      <Text>{record?.used ?? 0}</Text> */}
    </Show>
  );
};

const FromCheckBox = ({
  label = "",
  value = false,
  onChange = (boo) => Boolean,
}) => {
  return (
    <Checkbox value={value} onChange={(e) => onChange(e.target.checked)}>
      {label}
    </Checkbox>
  );
};

