import {
  Show,
  useShow,
  Typography,
  Tag,
  useOne,
  IResourceComponentsProps,
  MarkdownField,
  useTranslate,
  BooleanField,
  ImageField,
  NumberField,
  Checkbox,
  TagField,
  useMany,
  Avatar,
  TextField,
  Table,
  DateField
} from "@pankod/refine";
import { fieldLable } from "./index";
import { IVenue,ISubject, ICoupon, IStoreItem, IProduct,ICourse,IInstructor } from "interfaces";
import RichText from "components/RichText";
import React from "react";
import { useManyResource, useOneResource } from "utils";

const { Title, Text } = Typography;

export const CourseShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<ICourse>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  const sessions = record?.sessions.map((s,i) => ({number:i+1,...s}))
   
  
const organizersds =
record?.organizers?.map((item) => item._id) ?? []
  
  


   const instructorIds =
record?.instructors?.map((item) => item._id) ?? []

    
    

  
   const InstructorFilter = item => record?.instructors.includes(item._id)

   

   console.log('city',record?.venue)

//    <RelatedResourceOne
// Element={ ({name}) => <TagField value = {name}/>}
// id = {record?.city}
// resource= 'location/city'

// />




 const cityElement = useOneResource({id:record?.city as any ,resource:'location/city',map: ({name,_id}) => <TagField key = {_id}  value = {name}/>})
 const venueElement = useOneResource({id:record?.venue as any ,resource:'location/venue',map: ({name,_id}) => <TagField key = {_id}  value = {name}/>})

// Element = { ({name}) => <TagField value = {name}/>}
//         ides = {subjectIds}
//          resource= 'academy/subject'
//         filter={s => record?.subjects.includes(s._id)} />

const subjectsElements = useManyResource({resource:'academy/subject',map: ({name,_id}) => <TagField key = {_id}  value = {name}/>,filter:s => record?.subjects.includes(s._id) })


  // _id: string;
  // title: string;
  // description: string;
  // createDate: date;
  // image: string;
  // media: string[],
  // featured: boolean;
  // price: number;
  // visibility: string;
  // available: string;
  // hasShipping: boolean;
  // shippingPrice: number;
  // hasDiscount: boolean;
  // discountType: string;
  // discountAmount: number;
  // discountStartDate: date;
  // discountEndDate: date;

  // export interface ICourse extends IProduct {
  //   international: boolean;
  //   instructors: IInstructor[];
  //   institute: IInstitute;
  //   subjects: ISubject[];
  //   online: boolean;
  //   city: ICity;
  //   venue: IVenue;
  //   organizers: IUser[]
  // }
  

  

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t(fieldLable('image'))}</Title>
      <ImageField value = {record?.image}/>
      <Title level={5}>{t(fieldLable('name'))}</Title>
      <Text>{record?.title}</Text>

      <Title level={5}>{t(fieldLable('price'))}</Title>
      <NumberField value = {record?.price ?? 0}/>

      <Title level={5}>

      <Checkbox checked = {record?.featured} >{t(fieldLable('featured'))}</Checkbox>
      </Title>
     
      <Title level={5}>{t(fieldLable('international'))}</Title>
      <BooleanField value = {record?.international}/>

      <Title level={5}>{t(fieldLable('online'))}</Title>
      <BooleanField value = {record?.online}/>

      {record?.online && ( <>
      
        <Title level={5}>{t(fieldLable('url'))}</Title>
      <Text >{record?.url}</Text>
      </>
        
      )}

      <Title level={5}>{t(fieldLable('subjects'))}</Title>

      {subjectsElements}
      


              <Title level={5}>{t(fieldLable('instructors'))}</Title>
      

<RelatedResourceMany Element = {UserDisplay} ides = {instructorIds} resource= 'academy/instructor' filter={InstructorFilter} />



{record?.city && (
  <>
   <Title level={5}>{t(fieldLable('city'))}</Title>
{cityElement}
  </>

  
 

)}

{record?.venue && (
  <>
   <Title level={5}>{t(fieldLable('venue'))}</Title>
   {venueElement}

  </>




  
 

)}

<Title level={5}>{t(fieldLable('organizers'))}</Title>
<RelatedResourceMany
       Element = { ({name}) => <TagField value = {name}/>}
        ides = {organizersds}
         resource= 'user'
        filter={s => record?.organizers.includes(s._id)} />

      
      <Title level={5}>{t(fieldLable('description'))}</Title>
      <RichText value = {record?.description}/>

      <Title level={5}>{t(fieldLable('visibility'))}</Title>
      <TagField value = {record?.visibility}/>

      <Title level={5}>{t(fieldLable('available'))}</Title>
      <TagField value = {record?.available}/>
      
      <Title level={5}>{'Days'}</Title>
      <Table dataSource={sessions} columns={[{title:'day number',dataIndex:'number'},{title:'date',dataIndex:'start',render: d => <DateField value={d}/>},{title:'duration (hours)',dataIndex:'length',render: l => <NumberField value={l/60}/>}]} />;

    </Show>
  );
};

const RelatedResourceMany = ({ides= [''],filter,resource,Element}) => {

  // const subjectIds =
  // record?['name']?.map((item) => item._id) ?? []
  
      
      const idesSet = new Set(ides)
    const { data} = useMany({
      resource,
      ids: Array.from(idesSet),
      queryOptions: {
        enabled: ides.length > 0,
      },
    });



    const records = data?.data.filter(filter)

    const elem = records?.map(i => <Element key = {i._id} {...i} /> )
    
  
    return <div>{elem}</div>
}





const UserDisplay = ({image,name}) => {
  return <div>
    <Avatar src = {image} />
    <Text>{name}</Text>
  </div>
}

const FromCheckBox = ({label='',value=false,onChange = boo => Boolean}) => {

  return ( <Checkbox value = {value} onChange={e => onChange(e.target.checked)}>{label}</Checkbox>)

}