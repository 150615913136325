import {
  Show,
  useShow,
  Typography,
  Tag,
  useOne,
  IResourceComponentsProps,
  MarkdownField,
  useTranslate,
} from "@pankod/refine";

import { ICountry } from "interfaces";

const { Title, Text } = Typography;

export const CountryShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<ICountry>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t("country.fields.name")}</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>{t("country.fields.code")}</Title>
      <Text>
        <Tag>{record?.code}</Tag>
      </Text>

    </Show>
  );
};
