import {
  Show,
  useShow,
  Typography,
  Tag,
  useOne,
  IResourceComponentsProps,
  MarkdownField,
  useTranslate,
  useMany,
  TagField,
  Image,
  DateField,
  BooleanField,
} from "@pankod/refine";
import RichText from "components/RichText";

import { ICity, ICountry,IInstructor,ISubject,IUser } from "interfaces";

const { Title, Text } = Typography;

export const UserShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<IUser>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

  

//     const countryQueryResult = useOne<ICountry>({
//     resource: "location/countory",
//     id: record?.subjects + '',
//     queryOptions: {
//       enabled: !!record?.country,
//   },
// });


// const toSet = new Set(countryIds)
// const { data: countryData, isLoading } = useMany<ICountry>({
// resource: "location/countory",
// ids: Array.from(toSet),
// queryOptions: {
//   enabled: countryIds.length > 0,
// },
// });

// const { selectProps: countrySelectProps } = useSelect<ICountry>({
// resource: "location/countory",
// });

// export interface IUser {
//   _id: string;
//   name: string;
//   mapUrl: string;
//   date: date,
//   email: string;
//   role: string;
//   phone: string;
//   block: boolean,
//   confirmEmail: boolean;
//   scfhs: string;
//   profession: string;
//   workplace: string;
//   completeProfile:  boolean;
//   avatar: string;
//   gender: string;
// }

const fieldLable = lable => t(`user.fields.${lable}`)
  

  return (
    <Show isLoading={isLoading}>
  <div>
  <Title level={3}>account info</Title>
  <Title level={5}>{fieldLable('avatar')}</Title>
<Image src = {record?.avatar} />
      <Title level={5}>{fieldLable('name')}</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>{fieldLable('gender')}</Title>
      <Text>{record?.gender ?? 'not set'}</Text>


      <Title level={5}>{fieldLable('email')}</Title>
      <Text>{record?.email}</Text>

      <Title level={5}>{fieldLable('role')}</Title>
      <Text>{record?.role}</Text>

      <Title level={5}>{fieldLable('join')}</Title>
      <DateField value = {record?.date}/>

      <Title level={5}>{fieldLable('active')}</Title>
      <BooleanField value = {!record?.block}/>

      <Title level={5}>{fieldLable('bio')}</Title>
      <RichText value = {record?.bio}/>

  </div>

  <div>
  <Title level={3}>student info</Title>
  </div>
  <Title level={5}>{fieldLable('scfhs')}</Title>
      <Text>{record?.scfhs}</Text>
      <Title level={5}>{fieldLable('profession')}</Title>
      <Text>{record?.profession}</Text>
      <Title level={5}>{fieldLable('position')}</Title>
      <Text>{record?.position}</Text>
      <Title level={5}>{fieldLable('workplace')}</Title>
      <Text>{record?.workplace}</Text>
     

      
    </Show>
  );
};
