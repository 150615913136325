import {
  List,
  Table,
  TextField,
  useTable,
  IResourceComponentsProps,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useMany,
  useSelect,
  TagField,
  FilterDropdown,
  Select,
  ShowButton,
  useTranslate,
  useOne,
  Image,
  Avatar,
} from "@pankod/refine";
import { ICity,ICountry,IInstructor,ISubject } from "interfaces";



export const InstructorList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<IInstructor>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  // export interface ICountry {
  //   _id: string;
  //   name: string;
  //   code: string;
  // }

//   const categoryQueryResult = useOne<ICategory>({
//     resource: "categories",
//     id: "1",
// });

  const subjectIds =
    tableProps?.dataSource?.map((item) => item.subjects) 
    .reduce((p,c)=> p.concat(c),[])
    .map(s => s._id) ?? []

    
    const toSet = new Set(subjectIds)
  const { data: subjectData, isLoading } = useMany<ISubject>({
    resource: "academy/subject",
    ids: Array.from(toSet),
    queryOptions: {
      enabled: subjectIds.length > 0,
    },
  });

  const { selectProps: subjectSelectProps } = useSelect<ISubject>({
    resource: "academy/subject",
    optionLabel:'name',
    optionValue:'_id'
  });

  const getSubjects = (sub:any) => {
   return subjectData?.data.filter(s => sub.includes(s._id))
    .map(s => s.name)
  }

  return (
    <List>
      <Table {...tableProps} rowKey="id">

      <Table.Column
          dataIndex="image"
          
          title={t("instructor.fields.image")}
          render={(value) => <Avatar src = {value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
       
        <Table.Column
          dataIndex="name"
          
          title={t("instructor.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />

<Table.Column
          dataIndex= 'subjects'
          title={t("instructor.fields.subjects")}
          render={(value) => {
            if (isLoading) {
              return <TextField value={t("loading")} />;
            }
          

            return (
              <div>
 {getSubjects(value)?.map(s => <TagField value = {s}/>)}
              </div>
             
              
              
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("posts.fields.category.filter.placeholder")}
                {...subjectSelectProps}
              />
            </FilterDropdown>
          )}
        />
       
        
        
        <Table.Column<IInstructor>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record._id} />
              <ShowButton hideText size="small" recordItemId={record._id} />
              <DeleteButton hideText size="small" recordItemId={record._id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
