import { useState } from "react";
import {
  Create,
  Form,
  Input,
  Select,
  useSelect,
  IResourceComponentsProps,
  useForm,
  useTranslate,
  Upload,
} from "@pankod/refine";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

import {  ICity, IInstitute, IOrganization, ISubject } from "interfaces";
import RichText from "components/RichText";

export const OrganizationCreate: React.FC<IResourceComponentsProps> = () => {
  
  const sectorOptions = [
    { label: "Government", value: "government" },
    { label: "Private", value: "private" },
    { label: "Nonprofit", value: "nonprofit" }
  ];

  const industryOptions = [
    { label: "University", value: "university" },
    { label: "Hospital", value: "hospital" }
  ];


  // sector: { type: String ,enum: ["government","private",'nonprofit']} ,
  // industry: { type: String ,enum: ["university","hospital"]} ,

  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult } = useForm<IOrganization>();
  const record =  queryResult?.data?.data

  const { selectProps: countrySelectProps } = useSelect<ICity>({
    resource: "location/city",
    defaultValue: queryResult?.data?.data.city as string,
    optionLabel: "name",
    optionValue: "_id",
    
    
  });

  


  const filterOption = (inputValue, option) =>{

    return   option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
   }
  

  return (
    <Create saveButtonProps={saveButtonProps}>
      <Form  {...formProps} layout="vertical"
      
      
      >


      

        <Form.Item
          label={t("institute.fields.name")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Select'
          name='sector'
          rules={[
            {
              required: true,
            },
          ]}
        >

          
          {/* <AutoComplete options = {options} filterOption={(inputValue, option) =>
      option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }> */}

     

          <Select options = {sectorOptions}   />
         
        
        </Form.Item>

        <Form.Item
          label='Select'
          name='industry'
          rules={[
            {
              required: true,
            },
          ]}
        >

          
          {/* <AutoComplete options = {options} filterOption={(inputValue, option) =>
      option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }> */}

     

          <Select options = {industryOptions}   />
         
        
        </Form.Item>

        
        <Form.Item
          label={t("venue.fields.city")}
          name='city'
          rules={[
            {
              required: true,
            },
          ]}
        >

          
          {/* <AutoComplete options = {options} filterOption={(inputValue, option) =>
      option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }> */}

     

          <Select  {...countrySelectProps} filterOption={filterOption}  />
         
        
        </Form.Item>
        

       
        <Form.Item
          label={t("institute.fields.description")}
          name='description'
          
        >
          <RichText edit   />
        </Form.Item>

       
          
      </Form>
    </Create>
  );
};
