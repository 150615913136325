import {
  List,
  Table,
  TextField,
  useTable,
  IResourceComponentsProps,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useMany,
  useSelect,
  TagField,
  FilterDropdown,
  Select,
  ShowButton,
  useTranslate,
  useOne,
  NumberField,
  BooleanField,
  ImageField,
  Radio,
  CloneButton,
} from "@pankod/refine";

import { ICity,IVenue,ISubject,ICoupon, IStoreItem, IInstructor, ICourse,IOrder, IUser, IEnroll } from "interfaces";
import { fieldLable } from "./index";



export const EnrollList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<IEnroll>({
    initialSorter: [
      {
        field: "date",
        order: "desc",
      },
    ],
  });

  console.log(tableProps.dataSource?.length)
  console.log(tableProps.dataSource)
  const userIds =
    tableProps?.dataSource?.map((item) => item.user._id) ?? []

      const userSet = new Set(userIds)
  const { data: userData, isLoading:userLoding } = useMany<IUser>({
    resource: "user",
    ids: Array.from(userSet),
    queryOptions: {
      enabled: userIds?.length > 0,
    },
  });

  const courseIds =
    tableProps?.dataSource?.map((item) => item.course._id) ?? []

      const courseSet = new Set(courseIds)
  const { data: courseData, isLoading:courseLoding } = useMany<ICourse>({
    resource: "store/course",
    ids: Array.from(courseSet),
    queryOptions: {
      enabled: courseIds?.length > 0,
    },
  });
   
  const { selectProps: courseSelectProps } = useSelect<ICourse>({
    resource: "store/course",
    optionLabel:'title',
    optionValue:'_id'
  });
    

    // const ciytesIds =
    // tableProps?.dataSource?.map((item) => item.city._id) ?? [];

     
    // const cityIds =
    // tableProps?.dataSource?.filter(i => i.city ?? false).map((item) => item.city._id) ?? [];
    
    // const { selectProps: citySelectProps } = useSelect<ICity>({
    //   resource: "location/city",
    //   optionLabel:'name',
    //   optionValue:'_id'
    // });
  


    
  //   const toSet = new Set(instructorIds)
  // const { data: instructorData, isLoading } = useMany<IInstructor>({
  //   resource: "academy/instructor",
  //   ids: Array.from(toSet),
  //   queryOptions: {
  //     enabled: instructorIds.length > 0,
  //   },
  // });

  // const citySet = new Set(cityIds)

  // const { data: cityData, isLoading:isCityLoading } = useMany<ICity>({
  //   resource: "location/city",
  //   ids: Array.from(citySet),
  //   queryOptions: {
  //     enabled: instructorIds.length > 0,
  //   },
  // });

  const { selectProps: userNameSelectProps } = useSelect<IUser>({
    resource: "user",
    optionLabel:'name',
    optionValue:'_id'
  });

  // const { selectProps: userEmailSelectProps } = useSelect<IUser>({
  //   resource: "user",
  //   optionLabel:'email',
  //   optionValue:'_id'
  // });


  // const getInstructors = (sub:any) => {
  //   return instructorData?.data.filter(s => sub.includes(s._id))
  //    .map(s => s.name)
  //  }

   
  // export interface ICountry {
  //   _id: string;
  //   name: string;
  //   code: string;
  // }

//   const categoryQueryResult = useOne<ICategory>({
//     resource: "categories",
//     id: "1",
// });

// international: boolean;
//   instructors: IInstructor[];
//   institute: IInstitute;
//   subjects: ISubject[];
//   online: boolean;
//   city: ICity;
//   venue: IVenue;
//   organizers: IUser[]


 

  return (
    <List>
      <Table {...tableProps} rowKey="number">

      <Table.Column
          dataIndex="number"
          
          title={t(fieldLable('number'))}
          render={(value) => <TextField value={value} />}
         
        />
       
        <Table.Column
          dataIndex="date"
          
          title={t(fieldLable('date'))}
          render={(value) => <DateField value={value} />}
          sorter
        />





<Table.Column
          dataIndex= 'user'
          title={t(fieldLable('user'))}
          render={(value, record:IEnroll) => {
            if (userLoding) {
              return <TextField value={t("loading")} />;
            }

            return (
              <TagField
                value={
                  (userData?.data.find((item) => item._id === value)?.name) ?? 'not set'
                }
              />
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("order.fields.category.filter.placeholder")}
                {...userNameSelectProps}
              />
            </FilterDropdown>)}
          
        />

<Table.Column
          dataIndex= 'course'
          title={t(fieldLable('course'))}
          render={(value, record:IEnroll) => {
            if (courseLoding) {
              return <TextField value={t("loading")} />;
            }

            return (
              <TagField
                value={
                  (courseData?.data.find((item) => item._id === value)?.title) ?? 'not set'
                }
              />
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("order.fields.category.filter.placeholder")}
                {...courseSelectProps}
              />
            </FilterDropdown>)}
          
        />

{/* <Table.Column
          dataIndex= 'user'
          title={t(fieldLable('email'))}
          render={(value, record:ICourse) => {
            if (userLoding) {
              return <TextField value={t("loading")} />;
            }

            return (
              <TagField
                value={
                  (userData?.data.find((item) => item._id === value)?.email) ?? 'not set'
                }
              />
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("order.fields.category.filter.placeholder")}
                {...userEmailSelectProps}
              />
            </FilterDropdown>)}
          
        /> */}



{/* <Table.Column
          dataIndex= 'instructors'
          title={t(fieldLable('instructors'))}
          render={(value) => {
            if (isLoading) {
              return <TextField value={t("loading")} />;
            }
          

            return (
              <div>
 {getInstructors(value)?.map(s => <TagField value = {s}/>)}
              </div>
             
              
              
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("posts.fields.category.filter.placeholder")}
                {...instructorSelectProps}
              />
            </FilterDropdown>
          )}
        /> */}


 

        
        <Table.Column<IOrder>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              {/* <EditButton hideText size="small" recordItemId={record._id} />
              <ShowButton hideText size="small" recordItemId={record._id} />
              <CloneButton hideText size="small" recordItemId={record._id} /> */}
              <DeleteButton hideText size="small" recordItemId={record._id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
