import {
  List,
  Table,
  TextField,
  useTable,
  IResourceComponentsProps,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useMany,
  useSelect,
  TagField,
  FilterDropdown,
  Select,
  ShowButton,
  useTranslate,
  useOne,
  Image,
  Avatar,
  BooleanField,
} from "@pankod/refine";
import { ICity,ICountry,IInstructor,ISubject,IUser } from "interfaces";



export const UserList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<IUser>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  // export interface ICountry {
  //   _id: string;
  //   name: string;
  //   code: string;
  // }

//   const categoryQueryResult = useOne<ICategory>({
//     resource: "categories",
//     id: "1",
// });
const fieldLable = lable => t(`user.fields.${lable}`)
  
  return (
    <List>
      <Table {...tableProps} rowKey="id">

      <Table.Column
          dataIndex="avatar"
          
          title={fieldLable('avatar')}
          render={(value) => <Avatar src = {value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />
       
        <Table.Column
          dataIndex="name"
          
          title={fieldLable('name')}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />

<Table.Column
          dataIndex="date"
          
          title={fieldLable('join')}
          render={(value) => <DateField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />

<Table.Column
          dataIndex="block"
          
          title={fieldLable('active')}
          render={(value) => <BooleanField value={!value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />



<Table.Column
          dataIndex="role"
          title={fieldLable('role')}
          render={(value) => <TagField value={value} />}
        />
       
        
        
        <Table.Column<IUser>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record._id} />
              <ShowButton hideText size="small" recordItemId={record._id} />
              <DeleteButton hideText size="small" recordItemId={record._id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
