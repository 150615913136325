
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css';

 const RichText = ({value = '',onChange = ()=>'',edit = false}) => {
  // TODO fixt rich text
    return (
      <ReactQuill
      modules = {{toolbar:edit}}
      readOnly = {!edit} 
      theme='snow'
      value={value}
      onChange={onChange}
      
    />
    )
  
  }

  export default RichText