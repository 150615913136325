import {
  Show,
  useShow,
  Typography,
  Tag,
  useOne,
  IResourceComponentsProps,
  MarkdownField,
  useTranslate,
} from "@pankod/refine";

import { ICity, ICountry } from "interfaces";

const { Title, Text } = Typography;

export const CityShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<ICity>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

    const countryQueryResult = useOne<ICountry>({
    resource: "location/countory",
    id: record?.country + '',
    queryOptions: {
      enabled: !!record?.country,
  },
});



// const toSet = new Set(countryIds)
// const { data: countryData, isLoading } = useMany<ICountry>({
// resource: "location/countory",
// ids: Array.from(toSet),
// queryOptions: {
//   enabled: countryIds.length > 0,
// },
// });

// const { selectProps: countrySelectProps } = useSelect<ICountry>({
// resource: "location/countory",
// });

  

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t("city.fields.name")}</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>{t("city.fields.country")}</Title>
      
      <Tag>{countryQueryResult?.data?.data.name}</Tag>
        
      

    </Show>
  );
};
