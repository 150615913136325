import {
  Show,
  useShow,
  Typography,
  Tag,
  useOne,
  IResourceComponentsProps,
  MarkdownField,
  useTranslate,
  BooleanField,
} from "@pankod/refine";
import { fieldLable } from "./index";
import { IVenue,ISubject, ICoupon } from "interfaces";

const { Title, Text } = Typography;

export const CouponShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<ICoupon>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

   



// const toSet = new Set(countryIds)
// const { data: countryData, isLoading } = useMany<ICountry>({
// resource: "location/countory",
// ids: Array.from(toSet),
// queryOptions: {
//   enabled: countryIds.length > 0,
// },
// });

// const { selectProps: countrySelectProps } = useSelect<ICountry>({
// resource: "location/countory",
// });

  

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t(fieldLable('name'))}</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>{t(fieldLable('code'))}</Title>
      <Text>{record?.code}</Text>

      <Title level={5}>{t(fieldLable('kind'))}</Title>
      <Text>{record?.kind}</Text>

      <Title level={5}>{t(fieldLable('discountType'))}</Title>
      <Text>{record?.discountType}</Text>

      <Title level={5}>{t(fieldLable('discountAmount'))}</Title>
      <Text>{record?.discountType === 'fix' ? record?.discountAmount : `%${(record?.discountAmount ?? 0) * 100}`}</Text>

      <Title level={5}>{t(fieldLable('active'))}</Title>
      <BooleanField value={record?.publish}/>

      <Title level={5}>{t(fieldLable('limt'))}</Title>
      <Text>{record?.limt ?? 'unlimited'}</Text>

      <Title level={5}>{t(fieldLable('used'))}</Title>
      <Text>{record?.used ?? 0}</Text>


      
      

    </Show>
  );
};
