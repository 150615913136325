import {
  List,
  Table,
  TextField,
  useTable,
  IResourceComponentsProps,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useMany,
  useSelect,
  TagField,
  FilterDropdown,
  Select,
  ShowButton,
  useTranslate,
  useOne,
} from "@pankod/refine";
import { ICity,IVenue } from "interfaces";



export const VenueList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<IVenue>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  // export interface ICountry {
  //   _id: string;
  //   name: string;
  //   code: string;
  // }

//   const categoryQueryResult = useOne<ICategory>({
//     resource: "categories",
//     id: "1",
// });

  const ciytesIds =
    tableProps?.dataSource?.map((item) => item.city._id) ?? [];

    
    const toSet = new Set(ciytesIds)
  const { data: countryData, isLoading } = useMany<ICity>({
    resource: "location/city",
    ids: Array.from(toSet),
    queryOptions: {
      enabled: ciytesIds.length > 0,
    },
  });

  const { selectProps: countrySelectProps } = useSelect<ICity>({
    resource: "location/city",
    optionLabel:'name',
    optionValue:'_id'
  });

  return (
    <List>
      <Table {...tableProps} rowKey="id">
       
        <Table.Column
          dataIndex="name"
          
          title={t("venue.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />

<Table.Column
          dataIndex= 'city'
          title={t("venue.fields.city")}
          render={(value) => {
            if (isLoading) {
              return <TextField value={t("loading")} />;
            }

            return (
              <TextField
                value={
                  countryData?.data.find((item) => item._id === value)?.name
                }
              />
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("posts.fields.category.filter.placeholder")}
                {...countrySelectProps}
              />
            </FilterDropdown>
          )}
        />
       
        
        
        <Table.Column<ICity>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record._id} />
              <ShowButton hideText size="small" recordItemId={record._id} />
              <DeleteButton hideText size="small" recordItemId={record._id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
