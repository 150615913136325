import {
  List,
  Table,
  TextField,
  useTable,
  IResourceComponentsProps,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useMany,
  useSelect,
  TagField,
  FilterDropdown,
  Select,
  ShowButton,
  useTranslate,
  useOne,
  NumberField,
  BooleanField,
  ImageField,
  Radio,
} from "@pankod/refine";

import { IRefund, ICourse,IOrder, IUser } from "interfaces";
import { fieldLable } from "./index";



export const RefundList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<IRefund>({
    initialSorter: [
      {
        field: "date",
        order: "desc",
      },
    ],
  });

  console.log(tableProps.dataSource?.length)
  console.log(tableProps.dataSource)
  const userIds =
    tableProps?.dataSource?.map((item) => item.user._id) ?? []

      const userSet = new Set(userIds)
  const { data: userData, isLoading:userLoding } = useMany<IUser>({
    resource: "user",
    ids: Array.from(userSet),
    queryOptions: {
      enabled: userIds?.length > 0,
    },
  });


  const ordersIds =
    tableProps?.dataSource?.map((item) => item.user._id) ?? []

      const orderSet = new Set(userIds)
  const { data: orderData, isLoading:orderLoding } = useMany<IOrder>({
    resource: "store/order",
    ids: Array.from(orderSet),
    queryOptions: {
      enabled: ordersIds?.length > 0,
    },
  });
   
    

    // const ciytesIds =
    // tableProps?.dataSource?.map((item) => item.city._id) ?? [];

     
    // const cityIds =
    // tableProps?.dataSource?.filter(i => i.city ?? false).map((item) => item.city._id) ?? [];
    
    // const { selectProps: citySelectProps } = useSelect<ICity>({
    //   resource: "location/city",
    //   optionLabel:'name',
    //   optionValue:'_id'
    // });
  


    
  //   const toSet = new Set(instructorIds)
  // const { data: instructorData, isLoading } = useMany<IInstructor>({
  //   resource: "academy/instructor",
  //   ids: Array.from(toSet),
  //   queryOptions: {
  //     enabled: instructorIds.length > 0,
  //   },
  // });

  // const citySet = new Set(cityIds)

  // const { data: cityData, isLoading:isCityLoading } = useMany<ICity>({
  //   resource: "location/city",
  //   ids: Array.from(citySet),
  //   queryOptions: {
  //     enabled: instructorIds.length > 0,
  //   },
  // });

  const { selectProps: userNameSelectProps } = useSelect<IUser>({
    resource: "user",
    optionLabel:'name',
    optionValue:'_id'
  });

  const { selectProps: userEmailSelectProps } = useSelect<IUser>({
    resource: "user",
    optionLabel:'email',
    optionValue:'_id'
  });


  // const getInstructors = (sub:any) => {
  //   return instructorData?.data.filter(s => sub.includes(s._id))
  //    .map(s => s.name)
  //  }

   
  // export interface ICountry {
  //   _id: string;
  //   name: string;
  //   code: string;
  // }

//   const categoryQueryResult = useOne<ICategory>({
//     resource: "categories",
//     id: "1",
// });

// international: boolean;
//   instructors: IInstructor[];
//   institute: IInstitute;
//   subjects: ISubject[];
//   online: boolean;
//   city: ICity;
//   venue: IVenue;
//   organizers: IUser[]


 

  return (
    <List>
      <Table {...tableProps} rowKey="number">

      <Table.Column
          dataIndex="date"
          
          title='Date'
          render={(value) => <DateField value={value} />}
          sorter
        />




       
        

<Table.Column
          dataIndex="status"
          
          title={t('Status')}
          render={(value) => <TagField value={value} />}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
                <Radio.Group>
                    <Radio value="pending">Pending</Radio>
                    <Radio value="approved">Approved</Radio>
                    <Radio value="rejected">Rejected</Radio>
                </Radio.Group>
            </FilterDropdown>
        )}
        />

<Table.Column
          dataIndex="order"
          
          title={t('order.fields.number')}
          render={(value, record:IRefund) => {
            if (orderLoding) {
              return <TextField value={t("loading")} />;
            }

            return (
              <TagField
                value={
                  (orderData?.data.find((item) => item._id === value)?.number) ?? 'not set'
                }
              />
            );
          }}
          
        />
        <Table.Column
          dataIndex="order"
          
          title={t('order.fields.amount')}
          render={(value, record:IRefund) => {
            if (orderLoding) {
              return <TextField value={t("loading")} />;
            }

            return (
              <TagField
                value={
                  (orderData?.data.find((item) => item._id === value)?.amount) ?? 'not set'
                }
              />
            );
          }}
          sorter
        />

<Table.Column
          dataIndex= 'user'
          title={t('order.fields.customer')}
          render={(value, record:ICourse) => {
            if (userLoding) {
              return <TextField value={t("loading")} />;
            }

            return (
              <TagField
                value={
                  (userData?.data.find((item) => item._id === value)?.name) ?? 'not set'
                }
              />
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("order.fields.category.filter.placeholder")}
                {...userNameSelectProps}
              />
            </FilterDropdown>)}
          
        />

<Table.Column
          dataIndex= 'user'
          title={t('order.fields.email')}
          render={(value, record:ICourse) => {
            if (userLoding) {
              return <TextField value={t("loading")} />;
            }

            return (
              <TagField
                value={
                  (userData?.data.find((item) => item._id === value)?.email) ?? 'not set'
                }
              />
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("order.fields.category.filter.placeholder")}
                {...userEmailSelectProps}
              />
            </FilterDropdown>)}
          
        />



{/* <Table.Column
          dataIndex= 'instructors'
          title={t(fieldLable('instructors'))}
          render={(value) => {
            if (isLoading) {
              return <TextField value={t("loading")} />;
            }
          

            return (
              <div>
 {getInstructors(value)?.map(s => <TagField value = {s}/>)}
              </div>
             
              
              
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("posts.fields.category.filter.placeholder")}
                {...instructorSelectProps}
              />
            </FilterDropdown>
          )}
        /> */}


 

        
        <Table.Column<IOrder>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record._id} />
              <ShowButton hideText size="small" recordItemId={record._id} />
              <DeleteButton hideText size="small" recordItemId={record._id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
