

export * from "./list";
export * from "./create";
export * from "./edit";
export * from "./show";



export const fieldLable = lable => `product.fields.${lable}`
