// @ts-nocheck
import { useEffect, useState } from "react";
import {
  Edit,
  Form,
  Input,
  Select,
  IResourceComponentsProps,
  useForm,
  useSelect,
  useTranslate,
  Button,
  Checkbox,
  Upload,
  UploadFile,
  useList,
  DatePicker,
  TimePicker,
} from "@pankod/refine";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import moment from "moment";

import "react-mde/lib/styles/css/react-mde-all.css";
import { fieldLable } from "./index";
import {
  ICountry,
  ICity,
  IVenue,
  ISubject,
  ICoupon,
  IStoreItem,
  IProduct,
  ICourse,
  IUser,
  IOrder,
} from "interfaces";
import passGen from "generate-password";
import { toPercent, useManyResource } from "utils";
import RichText from "components/RichText";

const codeGen = () =>
  passGen.generate({
    length: 7,
    numbers: true,
  });

export const OrderEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult } = useForm<IOrder>();

  const record = queryResult?.data?.data;

  const paymentStatusOptions = [
    { label: "unpaid", value: "unpaid" },
    { label: "paid", value: "paid" },
    { label: "full refund", value: "full-refund" },
    { label: "partial refund", value: "partial-refund" },
    { label: "cancel", value: "cancel" },
  ];

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t(fieldLable("paymentStatus"))}
          name="paymentStatus"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={paymentStatusOptions} />
        </Form.Item>
      </Form>
    </Edit>
  );
};

const CodeGen = ({ value = "", onChange = (code) => "" }) => {
  return (
    <>
      <Button style={{ marginBottom: 8 }} onClick={() => onChange(codeGen())}>
        generate new code
      </Button>
      <Input value={value} onChange={onChange} />
    </>
  );
};

const FromCheckBox = ({
  label = "",
  value = false,
  onChange = (boo) => Boolean,
}) => {
  return (
    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}>
      {label}
    </Checkbox>
  );
};

{
  /* <Show isLoading={isLoading}>
      
  
     
      
      <Title level={5}>{t(fieldLable('online'))}</Title>
      <BooleanField value = {record?.online}/>

      {record?.online && ( <>
      
        <Title level={5}>{t(fieldLable('url'))}</Title>
      <Text >{record?.url}</Text>
      </>
        
      )}

      <Title level={5}>{fieldLable('subjects')}</Title>

      {subjectsElements}
      


              <Title level={5}>{fieldLable('instructors')}</Title>
      

<RelatedResourceMany Element = {UserDisplay} ides = {instructorIds} resource= 'academy/instructor' filter={InstructorFilter} />



{record?.city && (
  <>
   <Title level={5}>{fieldLable('city')}</Title>
{cityElement}
  </>

  
 

)}

{record?.venue && (
  <>
   <Title level={5}>{fieldLable('venue')}</Title>
   {venueElement}

  </>




  
 

)}

<Title level={5}>{fieldLable('organizers')}</Title>
<RelatedResourceMany
       Element = { ({name}) => <TagField value = {name}/>}
        ides = {organizersds}
         resource= 'user'
        filter={s => record?.organizers.includes(s._id)} />

      
      <Title level={5}>{t(fieldLable('description'))}</Title>
      <RichText value = {record?.description}/>

      <Title level={5}>{t(fieldLable('visibility'))}</Title>
      <TagField value = {record?.visibility}/>

      <Title level={5}>{t(fieldLable('available'))}</Title>
      <TagField value = {record?.available}/>
      

    </Show> */
}
