// @ts-nocheck
import { useEffect, useState } from "react";
import {
  Edit,
  Form,
  Input,
  Select,
  IResourceComponentsProps,
  useForm,
  useSelect,
  useTranslate,
  Button,
  Checkbox,
  Upload,
  UploadFile,
  useList,
  DatePicker,
  TimePicker,
  Table,
  
} from "@pankod/refine";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import moment from 'moment'

import "react-mde/lib/styles/css/react-mde-all.css";
import { fieldLable } from "./index";
import {
  ICountry,
  ICity,
  IVenue,
  ISubject,
  ICoupon,
  IStoreItem,
  IProduct,
  ICourse,
  IUser,
} from "interfaces";
import passGen from "generate-password";
import { toPercent, useManyResource } from "utils";
import RichText from "components/RichText";



const codeGen = () =>
  passGen.generate({
    length: 7,
    numbers: true,
  });

export const CourseEdit: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult } = useForm<ICourse>();

  const record = queryResult?.data?.data;


  

  

  const dateFormat = 'YYYY-MM-DD'
 
  
  

  const defaultStartDate = record?.startDate ? moment(record.startDate) : null
  const defaultEndDate = record?.startDate ? moment(record.endDate) : null
  
  
  //console.log( moment.toLoc(record?.startDate));
  
const timeFormat = 'h:mm a';



{/* <Select options={visibilityOptions} /> */}

  const subjectsOprions = useManyResource({resource:'academy/subject',map: ({name,_id}) => ({label:name,value:_id})})
  const instructorsOprions = useManyResource({resource:'academy/instructor',map: ({name,_id}) => ({label:name,value:_id})})
  const organizersOption = useManyResource({resource:'user',map: ({name,_id}) => ({label:name,value:_id}),filter: u => u.role === 'organizer'})

  
  const instituteOprions = useManyResource({resource:'academy/institute',map: ({name,_id}) => ({label:name,value:_id})})

  const formValues = formProps.form?.getFieldsValue()
  const venus = useManyResource({resource:'location/venue' })



  

  
  //const venueOprions = venus?.filter(v => v.city === currentCity ).map(v => ({label:v.name,value:v._id}))
  const venueOprions = venus?.map(v => ({label:v.name,value:v._id}))
 
  const { selectProps: citySelectProps } = useSelect<ICity>({
    resource: "location/city",
    defaultValue: queryResult?.data?.data.city,
    optionLabel: "name",
    optionValue: "_id",
    
    
  });

  






function createFromData(values: any) {

  const data = new FormData();

  

  console.log(values.date)

  const {image,venue,date,...rest} = values

  image &&  data.append('image', image.file);
  const city = venus?.find(v => v._id === venue)?.city ?? null
  const jsonData = JSON.stringify({...rest, city});

  data.append('json',jsonData)

  
   
//  if (venue) {
//   data.append('venue', venue);
//   const cityId = venus?.find(v => v._id === venue).city
//   data.append('city', cityId);
  
// }

//  if (date) {
  
//   const sessionsDate = values.date.map(d =>d.$d)
  
//   const [start,end] = sessionsDate
//   console.log(start,end)
//   data.append('startDate', start);
//   data.append('endDate', end);

//   //const cityId = venus?.find(v => v._id === values[key]).city
//  // data.append('city', cityId);
  
// }

return data


}






  const image: UploadFile = {
    url: record?.image ?? "",
    name: record?.image ?? "",
    size: 500,
    uid: record?.image ?? "",
  };

  const visibilityOptions = [
    { label: "Draft", value: "draft" },
    { label: "Public", value: "public" },
  ];

  const availableOptions = [
    { label: "Pre sell", value: "pre-sell" },
    { label: "Available", value: "available" },
    { label: "Unavailable", value: "not-available" },
    { label: "Solid Out", value: "solid-out" },
  ];

  useEffect(()=>{

  },[])

  return (
    <Edit saveButtonProps={saveButtonProps}>
       
      <Form
        {...formProps}
        layout="vertical"
        onFinish={async (values: any) => {
          const data = createFromData(values);

         
          return formProps.onFinish && formProps.onFinish(data);
        }}
      >
        <Form.Item>
          <Form.Item name="image" noStyle>
            <Upload.Dragger
              name="image"
              defaultFileList={[image]}
              accept="image/png, image/jpeg"
              listType="picture-card"
              maxCount={1}
              beforeUpload={() => false}
            >
              <p className="ant-upload-text">Drag & drop a file in this area</p>
            </Upload.Dragger>
          </Form.Item>
        </Form.Item>

        <Form.Item
          label={t(fieldLable("title"))}
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>


        <Form.Item
          label='Hashtag'
          name="hashtag"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Twitter Hashtag Url'
          name="twitterTagUrl"
        >
          <Input />
        </Form.Item>

        <Form.Item
          label='Instagram Hashtag Url'
          name="instagramTagUrl"
        >
          <Input />
        </Form.Item>

        

        {/* <Form.Item
          label={t(fieldLable("startTime"))}
          name="startTime"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TimePicker use12Hours format= {timeFormat} />
          
        </Form.Item> */}

        {/* <Form.Item
          label={t(fieldLable("endTime"))}
          name="endTime"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <TimePicker use12Hours format= {timeFormat} />
          
        </Form.Item> */}

<Form.Item label={'Group'} name="group">
          <Input  />
        </Form.Item>


        <Form.Item label={t(fieldLable("price"))} name="price">
          <Input type="number" />
        </Form.Item>

        <Form.Item name="featured">
          <FromCheckBox label={t(fieldLable("featured"))} />
        </Form.Item>


        <Form.Item name="online">
          <FromCheckBox label={t(fieldLable("online"))} />
        </Form.Item>

        <Form.Item label={t(fieldLable("url"))} name="url">
          <Input  />
        </Form.Item>

        <Form.Item
          label={t(fieldLable("subjects"))}
          name="subjects"
          
        >
          <Select options={subjectsOprions} mode="multiple"   />
        </Form.Item>

        <Form.Item
          label={t(fieldLable("instructors"))}
          name="instructors"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={instructorsOprions} mode="multiple"   />
        </Form.Item>

        <Form.Item
          label={'Institute'}
          name="institute"
          
        >
          <Select options={instituteOprions}    />
        </Form.Item>

        <Form.Item
          label={t(fieldLable("city"))}
          name="city"
        >
          <Select {...citySelectProps} />
        </Form.Item>

        <Form.Item
          label={t(fieldLable("venue"))}
          name="venue"
         
        >
          <Select options={venueOprions} />
        </Form.Item>

        <Form.Item
          label= 'Location Note'
          name="locationNote"
         
        >
          <Input  />
        </Form.Item>


        <Form.Item
          label={t(fieldLable("organizers"))}
          name="organizers"
          
        >
          <Select options={organizersOption} mode="multiple" />
        </Form.Item>

        
        
        <Form.Item label={t("product.fields.description")} name="description">
          <RichText edit />
        </Form.Item>

        <Form.Item label={t("Certificate Requirement")} name="certificateRequirement">
          <RichText edit />
        </Form.Item>

        <Form.Item label={t("Material And Kit")} name="materialAndKit">
          <RichText edit />
        </Form.Item>

        <Form.Item label={t("Prerequisites")} name="prerequisites">
          <RichText edit />
        </Form.Item>


        <Form.Item label='Terms' name="terms">
          <RichText edit />
        </Form.Item>


        <Form.Item
          label={t(fieldLable("visibility"))}
          name="visibility"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={visibilityOptions}  />
        </Form.Item>

        <Form.Item
          label={t(fieldLable("available"))}
          name="available"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select options={availableOptions} />
        </Form.Item>

        <Form.Item
          label={'Days'}
          name="sessions"
          
        >
          <SessionTable/>
        </Form.Item>

        
      </Form>
    </Edit>
  );
};

const SessionTable = ({value,onChange = () => []}) => {

  console.log(value);


  const addSession = () => {

    const lastSession = value.length > 0 ? value[value.length - 1] : null


    const session = {
      start: lastSession ? addDay(lastSession.start) : null,
      end:null,
      length: lastSession?.length ?? 60,
      checkIn:[],
      checkOut:[]
    }

    

    onChange([...value,session])
  }

  const updateRecourd = (record,prop,val) => {

    

    
    const updatedSessions = [...value]

    const currentSession = updatedSessions[record.number-1]

    
    currentSession[prop] = prop === 'length' ?  parseFloat(val) * 60 : val

    
    onChange(updatedSessions)


  }

  const removeRecord = (index) => {

    const updatedSessions = [...value]
    updatedSessions.splice(index, 1)

    onChange(updatedSessions)


  }

  const columns = [
    {
      title:'Day Number',
      dataIndex:'number',
     
    
    }
    ,{title:'Start Date',dataIndex:'start',render: (d,record) => <Input type="datetime-local"  onChange={e => updateRecourd(record,'start',e.target.value)} defaultValue = {d?.substring(0,19)}/>},
    {title:'Start Date',dataIndex:'start',render: (d,record) => <Input type="datetime-local"  onChange={e => updateRecourd(record,'start',e.target.value)} defaultValue = {d?.substring(0,19)}/>},
    
    {
      title:'Duration (Hours)',
      dataIndex:'end',
      render: (l,record) => <Input defaultValue={l/60} type="number" onKeyUp={e => updateRecourd(record,'length',e.target.value)}  />
     
    
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_,record) => <a onClick = {()=> removeRecord(record.number-1)}>Delete</a>,
    },
  ]

  const sessions = value?.map((s,i) => ({number:i+1,...s}))

return (
  <>
  <Button onClick={addSession} >Add Day</Button>
  <Table dataSource={sessions} columns={columns} />
  </>

)

}



const FromCheckBox = ({
  label = "",
  value = false,
  onChange = (boo) => Boolean,
}) => {
  return (
    <Checkbox checked={value} onChange={(e) => onChange(e.target.checked)}>
      {label}
    </Checkbox>
  );
};



function addDay(dateString,days = 1) {
  const date = new Date(dateString);
  date.setDate(date.getDate() + days)
  return date.toISOString()
}

{
  /* <Show isLoading={isLoading}>
      
  
     
      
      <Title level={5}>{t(fieldLable('online'))}</Title>
      <BooleanField value = {record?.online}/>

      {record?.online && ( <>
      
        <Title level={5}>{t(fieldLable('url'))}</Title>
      <Text >{record?.url}</Text>
      </>
        
      )}

      <Title level={5}>{fieldLable('subjects')}</Title>

      {subjectsElements}
      


              <Title level={5}>{fieldLable('instructors')}</Title>
      

<RelatedResourceMany Element = {UserDisplay} ides = {instructorIds} resource= 'academy/instructor' filter={InstructorFilter} />



{record?.city && (
  <>
   <Title level={5}>{fieldLable('city')}</Title>
{cityElement}
  </>

  
 

)}

{record?.venue && (
  <>
   <Title level={5}>{fieldLable('venue')}</Title>
   {venueElement}

  </>




  
 

)}

<Title level={5}>{fieldLable('organizers')}</Title>
<RelatedResourceMany
       Element = { ({name}) => <TagField value = {name}/>}
        ides = {organizersds}
         resource= 'user'
        filter={s => record?.organizers.includes(s._id)} />

      
      <Title level={5}>{t(fieldLable('description'))}</Title>
      <RichText value = {record?.description}/>

      <Title level={5}>{t(fieldLable('visibility'))}</Title>
      <TagField value = {record?.visibility}/>

      <Title level={5}>{t(fieldLable('available'))}</Title>
      <TagField value = {record?.available}/>
      

    </Show> */
}
