
import { AuthProvider } from "@pankod/refine";
import axios from "axios";

export const TOKEN_KEY = "refine-auth";

const API_URL : string = process.env.REACT_APP_API_URL ?? ''


export const authProvider: AuthProvider = {
  login: async ({ username, password }) => {
    const {data:user} = await axios.post(`${API_URL}/auth/user/login`,{email:username, password })

    if(!user) return Promise.reject(new Error("not allow"));

    if(user.role !== 'admin') return Promise.reject(new Error("not allow"))

   

      localStorage.setItem(TOKEN_KEY, user.token);
      return Promise.resolve();
  },
  logout: () => {
    localStorage.removeItem(TOKEN_KEY);
    return Promise.resolve();
  },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (token) {
      return Promise.resolve();
    }

    return Promise.reject();
  },
  getPermissions: () => Promise.resolve(),
  getUserIdentity: async () => {
    const token = localStorage.getItem(TOKEN_KEY);
    if (!token) {
      return Promise.reject();
    }

    return Promise.resolve({
      id: 1,
    });
  },
};
