import {
  Show,
  useShow,
  Typography,
  Tag,
  useOne,
  IResourceComponentsProps,
  MarkdownField,
  useTranslate,
  UrlField,
  Button,
} from "@pankod/refine";

import { IVenue } from "interfaces";

const { Title, Text } = Typography;

export const VenueShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<IVenue>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

    const countryQueryResult = useOne<IVenue>({
    resource: "location/city",
    id: record?.city + '',
    queryOptions: {
      enabled: !!record?.city,
  },
});



// const toSet = new Set(countryIds)
// const { data: countryData, isLoading } = useMany<ICountry>({
// resource: "location/countory",
// ids: Array.from(toSet),
// queryOptions: {
//   enabled: countryIds.length > 0,
// },
// });

// const { selectProps: countrySelectProps } = useSelect<ICountry>({
// resource: "location/countory",
// });

  

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t("city.fields.name")}</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>{t("venue.fields.city")}</Title>
      
      <Tag>{countryQueryResult?.data?.data.name}</Tag>


      <Title level={5}>{'Google Map Url'}</Title>

      {record?.mapUrl ? (<Button href= {record?.mapUrl} >View</Button>) : 'none'  }
     

      <Title level={5}>{'Booking URL'}</Title>
      {record?.bookingUrl ? (<Button href= {record?.bookingUrl} >View</Button>) : 'none'  }
      
      
        
      

    </Show>
  );
};
