import { useState } from "react";
import {
  Create,
  Form,
  Input,
  Select,
  useSelect,
  IResourceComponentsProps,
  useForm,
  useTranslate,
} from "@pankod/refine";

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

import { IVenue,ISubject } from "interfaces";

export const SubjectCreate: React.FC<IResourceComponentsProps> = () => {
  

  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult } = useForm<ISubject>();

  

  return (
    <Create saveButtonProps={saveButtonProps}>
       <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("subject.fields.name")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

      
          
      </Form>
    </Create>
  );
};
