import { useState } from "react";
import {
  Edit,
  Form,
  Input,
  Select,
  IResourceComponentsProps,
  useForm,
  useSelect,
  useTranslate,
  Button,
  Checkbox,
  Upload,
  UploadFile,
} from "@pankod/refine";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";
import { fieldLable } from "./index";
import { ICountry,ICity,IVenue,ISubject, ICoupon, IStoreItem, IProduct} from "interfaces";
import passGen from 'generate-password'
import { toPercent } from "utils";
import RichText from "components/RichText";

const codeGen = () => passGen.generate({
  length: 7,
numbers: true
})

export const StoreItemEdit: React.FC<IResourceComponentsProps> = () => {
 

  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult } = useForm<IProduct>();

  const record =  queryResult?.data?.data

  
 
 

  const image:UploadFile = {
    url:record?.image ?? '',
    name:record?.image ?? '',
    size:500,
    uid:record?.image ?? ''
  }

  const visibilityOptions = [
    {label:'Draft',value:'draft'},
    {label:'Public',value:'public'}
  ]
  
  const availableOptions = [
    {label:'Pre Sell',value:'pre-sell'},
    {label:'Available',value:'available'},
    {label:'Unavailable',value:'not-available'},
    {label:'Solid Out',value:'solid-out'}
  ]
  
  
  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical"
     onFinish={async (values:any) => {
        
      

      const data = new FormData()

      for (const key in values) {

        if(key === 'image' && values?.image) data.append(key,values[key].file)

        else if (key === 'subjects') {
          values.subjects.forEach(subject => {
            data.append(key,subject)
          });
        }

        else {
          data.append(key,values[key])
        }


       
      }
  
     return formProps.onFinish && formProps.onFinish(data)
 

      
  }}
      >

<Form.Item >
                    <Form.Item
                         name="image"
                         
                        
                       
                        noStyle
                    >
                        <Upload.Dragger
                        name="image"
                        defaultFileList={[image]}    
                       accept="image/png, image/jpeg"
                       listType="picture-card"
                           
                            maxCount={1}
                            
                            
                            beforeUpload={() => false}
                        >
                            <p className="ant-upload-text">
                                Drag & drop a file in this area
                            </p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>
        
        <Form.Item
          label={t(fieldLable('title'))}
          name="title"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t(fieldLable('price'))}
          name="price"
          
        >
         
         <Input type="number" />
        </Form.Item>

        {/* <Form.Item
           name="featured"
          
        >
         
         <FromCheckBox label={t(fieldLable('featured'))}/>
        </Form.Item> */}

        <Form.Item
          label={t("institute.fields.description")}
          name='description'
          
        >
          <RichText edit   />
        </Form.Item>

        <Form.Item
          label={t(fieldLable('visibility'))}
          name="visibility"
          rules={[
            {
              required: true,
            },
          ]}
        >
         <Select options={visibilityOptions}/>
        </Form.Item>

        <Form.Item
          label={t(fieldLable('available'))}
          name="available"
          rules={[
            {
              required: true,
            },
          ]}
        >
         <Select options={availableOptions}/>
        </Form.Item>


        
        <Form.Item
           name="hasShipping"
          
        >
         
         <FromCheckBox label={t(fieldLable('hasShipping'))}/>
        </Form.Item>

       



      
          
      </Form>
    </Edit>
  );


  
};

const CodeGen = ({value='',onChange= code =>''}) => {

  return (
    <>
    <Button style = {{marginBottom: 8}} onClick={() =>onChange(codeGen())}>generate new code</Button>
     <Input value={value} onChange={onChange} />
    </>
  )

}

const FromCheckBox = ({label='',value=false,onChange = boo => Boolean}) => {

  return ( <Checkbox value = {value} onChange={e => onChange(e.target.checked)}>{label}</Checkbox>)

}


{/* <Title level={5}>{t(fieldLable('image'))}</Title>
      <ImageField value = {record?.image}/>
      <Title level={5}>{t(fieldLable('name'))}</Title>
      <Text>{record?.title}</Text>

      <Title level={5}>{t(fieldLable('price'))}</Title>
      <NumberField value = {record?.price ?? 0}/>

      <Title level={5}>

      <Checkbox checked = {record?.featured} >{t(fieldLable('featured'))}</Checkbox>
      </Title>
     
      
      <Title level={5}>{t(fieldLable('description'))}</Title>
      <RichText value = {record?.description}/>

      <Title level={5}>{t(fieldLable('visibility'))}</Title>
      <TagField value = {record?.visibility}/>

      <Title level={5}>{t(fieldLable('available'))}</Title>
      <TagField value = {record?.available}/>

      <Title level={5}  >{t(fieldLable('hasShipping'))}</Title>
<BooleanField value= {record?.hasShipping} ></BooleanField> */}

