import {
  List,
  Table,
  TextField,
  useTable,
  IResourceComponentsProps,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useMany,
  useSelect,
  TagField,
  FilterDropdown,
  Select,
  ShowButton,
  useTranslate,
  useOne,
} from "@pankod/refine";
import { ICity,IVenue,ISubject } from "interfaces";



export const SubjectList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<ISubject>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  // export interface ICountry {
  //   _id: string;
  //   name: string;
  //   code: string;
  // }

//   const categoryQueryResult = useOne<ICategory>({
//     resource: "categories",
//     id: "1",
// });

 

  return (
    <List>
      <Table {...tableProps} rowKey="id">
       
        <Table.Column
          dataIndex="name"
          
          title={t("subject.fields.name")}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />


       
        
        
        <Table.Column<ISubject>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record._id} />
              <ShowButton hideText size="small" recordItemId={record._id} />
              <DeleteButton hideText size="small" recordItemId={record._id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
