import {
  List,
  Table,
  TextField,
  useTable,
  IResourceComponentsProps,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useMany,
  useSelect,
  TagField,
  FilterDropdown,
  Select,
  ShowButton,
  useTranslate,
  useOne,
  NumberField,
  BooleanField,
  ImageField,
  CloneButton,
} from "@pankod/refine";

import { ICity,IVenue,ISubject,ICoupon, IStoreItem, IInstructor, ICourse } from "interfaces";
import { fieldLable } from "./index";



export const CourseList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter } = useTable<ICourse>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  const instructorIds =
    tableProps?.dataSource?.map((item) => item.instructors) 
    .reduce((p,c)=> p.concat(c),[])
    .map(s => s._id) ?? []

    // const ciytesIds =
    // tableProps?.dataSource?.map((item) => item.city._id) ?? [];

     
    const cityIds =
    tableProps?.dataSource?.filter(i => i.city ?? false).map((item) => item.city._id) ?? [];
    
    const { selectProps: citySelectProps } = useSelect<ICity>({
      resource: "location/city",
      optionLabel:'name',
      optionValue:'_id'
    });
  


    
    const toSet = new Set(instructorIds)
  const { data: instructorData, isLoading } = useMany<IInstructor>({
    resource: "academy/instructor",
    ids: Array.from(toSet),
    queryOptions: {
      enabled: instructorIds.length > 0,
    },
  });

  const citySet = new Set(cityIds)

  const { data: cityData, isLoading:isCityLoading } = useMany<ICity>({
    resource: "location/city",
    ids: Array.from(citySet),
    queryOptions: {
      enabled: instructorIds.length > 0,
    },
  });

  const { selectProps: instructorSelectProps } = useSelect<IInstructor>({
    resource: "academy/instructor",
    optionLabel:'name',
    optionValue:'_id'
  });

  const getInstructors = (sub:any) => {
    return instructorData?.data.filter(s => sub.includes(s._id))
     .map(s => s.name)
   }

   
  // export interface ICountry {
  //   _id: string;
  //   name: string;
  //   code: string;
  // }

//   const categoryQueryResult = useOne<ICategory>({
//     resource: "categories",
//     id: "1",
// });

// international: boolean;
//   instructors: IInstructor[];
//   institute: IInstitute;
//   subjects: ISubject[];
//   online: boolean;
//   city: ICity;
//   venue: IVenue;
//   organizers: IUser[]


 

  return (
    <List>
      <Table {...tableProps} rowKey="id">

      <Table.Column
          dataIndex="image"
          width = '200px'

          
          title={t(fieldLable('image'))}
          render={(value) => <ImageField   value={value}  />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />

       
        <Table.Column
          dataIndex="title"
          
          title={t(fieldLable('name'))}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />

<Table.Column
          dataIndex="group"
          
          title={'Group'}
          render={(value) => <TextField value={value ?? 'none'} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />

<Table.Column
          dataIndex= 'city'
          title={t(fieldLable('city'))}
          render={(value, record:ICourse) => {
            if (isCityLoading) {
              return <TextField value={t("loading")} />;
            }

            return (
              <TagField
                value={
                  (cityData?.data.find((item) => item._id === value)?.name) ?? (record.online ? 'online':'not set')
                }
              />
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("posts.fields.category.filter.placeholder")}
                {...citySelectProps}
              />
            </FilterDropdown>
          )}
        />

<Table.Column
          dataIndex="price"
          
          title={t(fieldLable('price'))}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />

<Table.Column
          dataIndex="waitList"
          
          title={'Waiting'}
          render={(value) => <TextField value={value.length} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />

<Table.Column
          dataIndex= 'instructors'
          title={t(fieldLable('instructors'))}
          render={(value) => {
            if (isLoading) {
              return <TextField value={t("loading")} />;
            }
          

            return (
              <div>
 {getInstructors(value)?.map(s => <TagField value = {s}/>)}
              </div>
             
              
              
            );
          }}
          filterDropdown={(props) => (
            <FilterDropdown {...props}>
              <Select
                style={{ minWidth: 200 }}
                mode="multiple"
                placeholder={t("posts.fields.category.filter.placeholder")}
                {...instructorSelectProps}
              />
            </FilterDropdown>
          )}
        />


 

        
        <Table.Column<ICourse>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record._id} />
              <ShowButton hideText size="small" recordItemId={record._id} />
              <CloneButton hideText size="small" recordItemId={record._id} />
              <DeleteButton hideText size="small" recordItemId={record._id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
