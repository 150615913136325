import { useState } from "react";
import {
  Edit,
  Form,
  Input,
  Select,
  IResourceComponentsProps,
  useForm,
  useSelect,
  useTranslate,
} from "@pankod/refine";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

import { ICountry,ICity} from "interfaces";

export const CityEdit: React.FC<IResourceComponentsProps> = () => {
 

  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult } = useForm<ICity>();

  const { selectProps: countrySelectProps } = useSelect<ICity>({
    resource: "location/countory",
    defaultValue: queryResult?.data?.data.country._id,
    optionLabel: "name",
    optionValue: "_id",
    
  });

  console.log('props',countrySelectProps)

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical">
        <Form.Item
          label={t("city.fields.name")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("city.fields.country")}
          name='country'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...countrySelectProps}  />
        </Form.Item>
          
      </Form>
    </Edit>
  );
};
