// @ts-nocheck
import {
  Show,
  useShow,
  Typography,
  Tag,
  useOne,
  IResourceComponentsProps,
  MarkdownField,
  useTranslate,
  BooleanField,
  ImageField,
  NumberField,
  Checkbox,
  TagField,
  useMany,
  Avatar,
  TextField,
  Table,
  DateField
} from "@pankod/refine";
import { fieldLable } from "./index";
import { IVenue,ISubject, ICoupon, IStoreItem, IProduct,ICourse,IInstructor, IOrder, IUser } from "interfaces";
import RichText from "components/RichText";
import React from "react";
import { useManyResource, useOneResource } from "utils";
import { Badge, Descriptions, List } from "antd";

const { Title, Text } = Typography;

export const EnrollShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<IOrder>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

   
  
  const {data:userData} = useOne<IUser>({
    resource: "user",
    id: record?.customer + '',
    queryOptions: {
      enabled: !!record?.customer,
  },
});

const couponsIds = record?.coupons.map(c => c._id) || []

const {data:couponsData} = useMany<ICoupon>({
  resource: "store/coupon",
  ids:couponsIds,
  queryOptions: {
          enabled: couponsIds.length > 0,
  }
})

const productsIds = record?.items.map(c => c.product) || []

const {data:porductData} = useMany<IProduct>({
  resource: "store/product",
  ids:productsIds,
  queryOptions: {
          enabled: productsIds.length > 0,
  }
})

console.log(porductData?.data)

const itemProducts = porductData?.data.filter(p => productsIds.includes(p._id))

console.log(itemProducts)



// const subjectIds =
// record?.subjects?.map((item) => item._id) ?? []

    
//     const toSet = new Set(subjectIds)
//   const { data: subjectData} = useMany<ISubject>({
//     resource: "academy/subject",
//     ids: Array.from(toSet),
//     queryOptions: {
//       enabled: subjectIds.length > 0,
//     },
//   });

const user = userData?.data
  
  


   

    
    

  
   

   

  

//    <RelatedResourceOne
// Element={ ({name}) => <TagField value = {name}/>}
// id = {record?.city}
// resource= 'location/city'

// />




 //const cityElement = useOneResource({id:record?.city as any ,resource:'location/city',map: ({name,_id}) => <TagField key = {_id}  value = {name}/>})
// const venueElement = useOneResource({id:record?.venue as any ,resource:'location/venue',map: ({name,_id}) => <TagField key = {_id}  value = {name}/>})

// Element = { ({name}) => <TagField value = {name}/>}
//         ides = {subjectIds}
//          resource= 'academy/subject'
//         filter={s => record?.subjects.includes(s._id)} />

//const subjectsElements = useManyResource({resource:'academy/subject',map: ({name,_id}) => <TagField key = {_id}  value = {name}/>,filter:s => record?.subjects.includes(s._id) })


// export interface IOrder {
//   _id: string;
//   number: string;
//   customer: IUser;
//   paymentStatus: string;
//   shippingStatus: string;
//   subtotal: number;
//   date: date;
//   total: number;
//   tax: number;
//   amount: number;
//   paymentMethod: string;
//   transactionNumber: string;
//   discount: number;
//   coupons: ICoupon[];
//   items: IOrderItem[];
 
// }

// export interface IOrderItem {
//   _id: string;
//   product: IProduct;
//   quantity: number;
//   price: number;
//   date: date;
// }
  

  

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t(fieldLable('number'))}</Title>
      <TextField value = {record?.number}/>

      <Title level={5}>{t(fieldLable('date'))}</Title>
      <DateField value = {record?.date}/>


      <Title level={5}>{t(fieldLable('customer'))}</Title>
      <TextField value = {`${user?.name} - ${user?.email}`}/>

      <Descriptions column = {1}  title={t(fieldLable('info'))} bordered>
    <Descriptions.Item label="Subtotal">{record?.subtotal}</Descriptions.Item>
    <Descriptions.Item label="discount">{record?.discount}</Descriptions.Item>
    <Descriptions.Item label="total without tax">{record?.total}</Descriptions.Item>
    <Descriptions.Item label="tax">{record?.tax}</Descriptions.Item>
    <Descriptions.Item label="total with tax">{record?.amount}</Descriptions.Item>


    
  
     
  </Descriptions>

  <List
      header={<div>items</div>}
      
      bordered
      dataSource={record?.items}
      renderItem={item => (
        <List.Item>
          
          <Typography.Text >{itemProducts?.find(p => p._id === item.product).title} : {item.price} x {item.quantity}</Typography.Text> 

         
         
        </List.Item>
      )}
    />

      <Title level={5}>{t(fieldLable('paymentStatus'))}</Title>
      <TagField value = {record?.paymentStatus}/>

      <Title level={5}>{t(fieldLable('paymentMethod'))}</Title>
      <TagField value = {record?.paymentMethod}/>

      <Title level={5}>{t(fieldLable('transactionNumber'))}</Title>
      <TextField value = {record?.transactionNumber ?? 'not set'}/>

      
      <Title level={5}>{t(fieldLable('coupons'))}</Title>
      {couponsData?.data.map(c => <TagField value = {c.code}/> )}
      
      
      

      
      {/* <Title level={5}>{t(fieldLable('name'))}</Title>
      <Text>{record?.title}</Text>

      <Title level={5}>{t(fieldLable('price'))}</Title>
      <NumberField value = {record?.price ?? 0}/>

      <Title level={5}>

      <Checkbox checked = {record?.featured} >{t(fieldLable('featured'))}</Checkbox>
      </Title>
     
      <Title level={5}>{t(fieldLable('international'))}</Title>
      <BooleanField value = {record?.international}/>

      <Title level={5}>{t(fieldLable('online'))}</Title>
      <BooleanField value = {record?.online}/>

      {record?.online && ( <>
      
        <Title level={5}>{t(fieldLable('url'))}</Title>
      <Text >{record?.url}</Text>
      </>
        
      )}

      <Title level={5}>{fieldLable('subjects')}</Title>

      {subjectsElements}
      


              <Title level={5}>{fieldLable('instructors')}</Title>
      





{record?.city && (
  <>
   <Title level={5}>{fieldLable('city')}</Title>
{cityElement}
  </>

  
 

)}

{record?.venue && (
  <>
   <Title level={5}>{fieldLable('venue')}</Title>
   {venueElement}

  </>




  
 

)}

<Title level={5}>{fieldLable('organizers')}</Title>

      
      <Title level={5}>{t(fieldLable('description'))}</Title>
      <RichText value = {record?.description}/>

      <Title level={5}>{t(fieldLable('visibility'))}</Title>
      <TagField value = {record?.visibility}/>

      <Title level={5}>{t(fieldLable('available'))}</Title>
      <TagField value = {record?.available}/>
       */}

    </Show>
  );
};







const UserDisplay = ({image,name}) => {
  return <div>
    <Avatar src = {image} />
    <Text>{name}</Text>
  </div>
}

const FromCheckBox = ({label='',value=false,onChange = boo => Boolean}) => {

  return ( <Checkbox value = {value} onChange={e => onChange(e.target.checked)}>{label}</Checkbox>)

}