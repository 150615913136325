import {
  Show,
  useShow,
  Typography,
  Tag,
  useOne,
  IResourceComponentsProps,
  MarkdownField,
  useTranslate,
} from "@pankod/refine";

import { IVenue,ISubject } from "interfaces";

const { Title, Text } = Typography;

export const SubjectShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { queryResult } = useShow<ISubject>();
  const { data, isLoading } = queryResult;
  const record = data?.data;

   



// const toSet = new Set(countryIds)
// const { data: countryData, isLoading } = useMany<ICountry>({
// resource: "location/countory",
// ids: Array.from(toSet),
// queryOptions: {
//   enabled: countryIds.length > 0,
// },
// });

// const { selectProps: countrySelectProps } = useSelect<ICountry>({
// resource: "location/countory",
// });

  

  return (
    <Show isLoading={isLoading}>
      <Title level={5}>{t("subject.fields.name")}</Title>
      <Text>{record?.name}</Text>

      
      

    </Show>
  );
};
