// @ts-nocheck
import { ArrowDownOutlined, ArrowUpOutlined } from "@ant-design/icons";
import {
  Typography,
  Button,
  Show,
  Space,
  useList,
  MarkdownField,
  useOne,
  useUpdate,
  LayoutWrapper,
  Card,
  Col,
  Row,
  Statistic,
  Table,
} from "@pankod/refine";

import { IPost, ICategory,IProduct, IOrder, ICourse, IUser } from "interfaces";
import { Line, LineChart } from "recharts";
import Chart from "./chart";
import PieC from "./piyChar";
import TodayReport from "./today";
import { groupData } from "./util";


const { Title, Text } = Typography;





export const Dashboard: React.FC = () => {
  const { data, isLoading } = useList<IOrder>({
      resource: "store/order",
      // config: {
      //     filters: [
      //         {
      //             field: "status",
      //             operator: "eq",
      //             value: "draft",
      //         },
      //     ],
      //     pagination: { pageSize: 1 },
      // },
  });

  const { data:productData, isLoading:loadingProduct } = useList<IProduct | ICourse>({
    resource: "store/product",
    // config: {
    //     filters: [
    //         {
    //             field: "status",
    //             operator: "eq",
    //             value: "draft",
    //         },
    //     ],
    //     pagination: { pageSize: 1 },
    // },
});

const { data:cityDatas, } = useList<IProduct | ICourse>({
    resource: "location/city",
});

  const orders = data?.data ?? [];

  const groupByDay = d => d.substring(0,10)
  const groupByMonth = d => d.substring(0,7)
  const groupByYear = d => d.substring(0,4)

  const ordersGroupByDay = groupData(orders, o => groupByDay(o.date))
  const ordersGroupByMonth = groupData(orders, o => groupByMonth(o.date))
  const ordersGroupByYear = groupData(orders, o => groupByYear(o.date))

  

  const todayStart = new Date()
  todayStart.setUTCHours(0,0,0,0)

  const todayEnd = new Date()
  todayEnd.setUTCHours(23,59,59,999)

  const isToday = date => {

    const dt = new Date(date) 

    console.log(dt,todayStart)

    return dt.getTime() >= todayStart.getTime() && dt.getTime() <=todayEnd.getTime()

  }
  

  //const [todayOrders] = ordersGroupByDay.filter(r => isToday(r.date))

  //console.log(todayOrders,'todayOrders')



  const graphData = ordersGroupByDay.map(r => createReport(r?.name,r?.data))
  
  
const productList =  sellsPerProduct(ordersGroupByYear[0]?.data)

const productObject = createProductObject(productData,cityDatas,productList)

const productsScor = productData?.data.filter(p => productList.find(r => r.product === p._id))
.map(p => ({...p,amount:productList.find(r => r.product === p._id)?.amount}))

console.log(productList)
  

  const mutationResult = useUpdate<IOrder>();

  const { mutate, isLoading: mutateIsLoading } = mutationResult;

  const handleUpdate = (item: IOrder, status: string) => {
      mutate({ resource: "store/order", id: item._id, values: { ...item, status } });
  };

 
  
  return (
      <Show
          title="Dashboard"
        
          isLoading={isLoading }
          pageHeaderProps={{
              ghost: true,
              extra: null,
          }}
          
      >



{/* <TodayReport {...createReport('today',todayOrders?.data)}/> */}
<Chart data={graphData}/>


<div style={{ margin: 8,width:100 }}>

</div>



<TopProduct productData = {productObject}/>

      </Show>
  );
};



  

  const createReport = (name,orders:IOrder[]) => {

    if(!orders) {
      return  {name,orders:0,revenue:0,cancel:0,refund:0}
    }

  
    const revenue = parseFloat(orders.filter(order => order.paymentStatus === 'paid').reduce((p,a)=> p+a.amount,0).toFixed(2)) 
    
    const cancel = orders.filter(order => order.paymentStatus === 'cancel').length
    
    const refund = orders.filter(order => order.paymentStatus === 'full-refund' || order.paymentStatus === 'partial-refund' ).length
     
        //   name: 'Month 1',
        //   revenue: 4000,
        //   orders: 800,
        //   cancels: 30,
        //   refunds:5
        // },
    
        return {name,orders:orders.length,revenue,cancel,refund}
      
    
    }

   

    /**
     * top courses
     * top city
     */

    const sellsPerProduct = (orders ) => {

        if(!orders) return []
        
        const orderProducts = orders.flatMap(o => o.items.map(i => ({product:i.product,amount:i.price* i.quantity,units:i.quantity,customer:o.customer})))
        .reduce((p,a) => {

            if(!p[a.product]) {
                p[a.product] = {amount:a.amount,units:a.units,customers:[a.customer]}
                return p
            }
            
            p[a.product].amount += a.amount
            p[a.product].units += a.units
            p[a.product].customers.push(a.customer)

            return p

        },{})

        const topProduct = Object.keys(orderProducts).map(key => ({product:key, amount:orderProducts[key].amount, units:orderProducts[key].units,customers:orderProducts[key].customers}))

        return topProduct
        

    }

    const createProductObject = (productData,cityData,productList) => {
        const fn = course => {
            if(course?.kind !== 'Course') return null
            if(course.online) return 'Online'

            return cityData?.data.find(c => c._id === course.city).name
        }
        return productList.map(p => {

            const courrntProduct = productData?.data.find(prod => prod._id === p.product)
            const location = fn(courrntProduct)

            return {
                ...p,
                kind: courrntProduct?  courrntProduct?.kind ?? 'Product' : 'deleted',
                location,
                product: courrntProduct?.title ,

            }


        })
    }

    function TopProduct({productData}) {

        const { data:productDatas, isLoading:loadingProduct } = useList<IProduct | ICourse>({
            resource: "store/product",
        });

        const { data:cityDatas, } = useList<IProduct | ICourse>({
            resource: "location/city",
        });



        const { data:userDatas,  } = useList<IUser>({
            resource: "user",
        });

        const func = (customers,fun = i => i) => {
           const d = userDatas?.data.filter(u => customers.includes(u._id))
            .filter(fun)

            return d?.length

        }

        const productName = id =>  {

            const product = productDatas?.data?.find(p => p._id === id)

            if(!product) return null

            if(product.kind !== 'Course') return product.title

            const course = product as ICourse
            if(course.online) return `${course.title} - online`

            return `${course.title} - ${cityDatas?.data.find(c => c._id === course?.city)?.name}`

            
        }

        const columns = [
            {
              title:'Product Name',
              dataIndex:'product',
              render: id => id ?? 'deleted product'
             },

             {
                title:'Product Type',
                dataIndex:'kind',
                
               },

             {
                title:'Location',
                dataIndex:'location',
               },

             {
                title:'Revenue',
                dataIndex:'amount',
               },

               {
                title:'Units',
                dataIndex:'units',
               },

               {
                title:'Male',
                dataIndex:'customers',
                render: customers => <Text>{func(customers,u => u.gender === 'male')}</Text>
               },

               {
                title:'Female',
                dataIndex:'customers',
                render: customers => <Text>{func(customers,u => u.gender === 'female')}</Text>
               },

               
            
          ]

        return (
            <>
            <Title>top product</Title>
            <Table dataSource={productData.sort((a,b)=> b.amount - a.amount)} columns={columns} />
            </>
        )

    }


    /**
     * what i need to do 
     
     * how i do
     * why i do it
     */

    /**
     * 5 wget  to display data
     * 1. today -> sells/revneu
     * 2. top course
     * 3. top cities
     * 4 char 
     * 5 .gender
     */

    // {product,location,}
    