import { useState } from "react";
import {
  Edit,
  Form,
  Input,
  Select,
  IResourceComponentsProps,
  useForm,
  useSelect,
  useTranslate,
  FileField,
  getValueFromEvent,
  ImageField,
  Upload,
  file2Base64,
  useApiUrl,
  UploadFile,
  
} from "@pankod/refine";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

import { ICountry,ICity,IInstitute,ISubject,IFile} from "interfaces";
import httpClient from "httpClient";
import RichText from "components/RichText";





export const InstituteEdit: React.FC<IResourceComponentsProps> = () => {
 
  

  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult } = useForm<IInstitute>();
  const record =  queryResult?.data?.data

  const { selectProps: countrySelectProps } = useSelect<ISubject>({
    resource: "academy/subject",
    defaultValue: queryResult?.data?.data.subjects.map(s => s._id),
    optionLabel: "name",
    optionValue: "_id",
    
  });

  const image:UploadFile = {
    url:record?.logo ?? '',
    name:record?.logo ?? '',
    size:500,
    uid:record?.logo ?? ''
  }


 
  
  return (
    <Edit  saveButtonProps={saveButtonProps}>
      <Form  {...formProps} layout="vertical"
      
      onFinish={async (values:any) => {
        
      

        const data = new FormData()

        for (const key in values) {

          if(key === 'logo' && values?.logo) data.append(key,values[key].file)

          else if (key === 'subjects') {
            values.subjects.forEach(subject => {
              data.append(key,subject)
            });
          }

          else {
            data.append(key,values[key])
          }


         
        }
    
       return formProps.onFinish && formProps.onFinish(data)
   

        
    }}
      
      
      >

      <Form.Item >
                    <Form.Item
                         name="logo"
                         
                        
                       
                        noStyle
                    >
                        <Upload.Dragger
                        name="image"
                        defaultFileList={[image]}    
                       accept="image/png, image/jpeg"
                       listType="picture-card"
                           
                            maxCount={1}
                            
                            
                            beforeUpload={() => false}
                        >
                            <p className="ant-upload-text">
                                Drag & drop a file in this area
                            </p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>

      

        <Form.Item
          label={t("institute.fields.name")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        

        

        <Form.Item
          label={t("institute.fields.subjects")}
          name='subjects'
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select {...countrySelectProps}  mode="multiple"  />
        </Form.Item>

        <Form.Item
          label={t("institute.fields.description")}
          name='description'
          
        >
          <RichText edit   />
        </Form.Item>

        <Form.Item
          label={t("institute.fields.summary")}
          name="summary"
          rules={[
            {
              required: true,
              max:80
            },
          ]}
        >
          <Input />
        </Form.Item>
          
      </Form>
     
    </Edit>
  );
};

