import { useState } from "react";
import {
  Create,
  Form,
  Input,
  Select,
  useSelect,
  IResourceComponentsProps,
  useForm,
  useTranslate,
  Upload,
  UploadFile,
} from "@pankod/refine";

import passGen from 'generate-password'

import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

import {  IInstitute, IInstructor, ISubject, IUser } from "interfaces";
import RichText from "components/RichText";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  

  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult } = useForm<IUser>();
  const record =  queryResult?.data?.data

  const pass = passGen.generate({
    length: 10,
	numbers: true
  })


  const fieldLable = lable => t(`user.fields.${lable}`)
 // ["admin","student",'organizer']

  const roleOptions = [
    {label:'student',value: 'student'},
    {label:'organizer',value: 'organizer'},
    {label:'lms manager',value: 'lms-manager'},
    {label:'admin',value: 'admin'}
  ]

  const positionOptions = [
    {label:'bachelor student',value: 'bachelor-student'},
    {label:'intern',value: 'intern'},
    {label:'graduate',value: 'graduate'},
    {label:'employee',value: 'employee'},
    {label:'master student',value: 'master-student'}
  ]

  const professionOptions = [
    {label:'physiotherapist',value: 'physiotherapist'},
    {label:'occupational therapist',value: 'occupational-therapist'},
    {label:'other',value: 'other'}
  ]
  
  return (
    <Create  saveButtonProps={saveButtonProps}>
      <Form  {...formProps} layout="vertical"
      
      onFinish={async (values:any) => {
        
      

        const data = new FormData()

        for (const key in values) {

          if(key === 'avatar' && values?.avatar ) values[key] &&  data.append(key,values[key].file)

          else {
            data.append(key,values[key])
          }


         
        }
    
       return formProps.onFinish && formProps.onFinish(data)
   

        
    }}
      
      
      >

      <Form.Item >
                    <Form.Item
                         name="avatar"
                         
                        
                       
                        noStyle
                    >
                        <Upload.Dragger
                       accept="image/png, image/jpeg"
                       listType="picture-card"
                           
                            maxCount={1}
                            
                            
                            beforeUpload={() => false}
                        >
                            <p className="ant-upload-text">
                                Drag & drop a file in this area
                            </p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>

      

        <Form.Item
          label={fieldLable('name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        

        

        <Form.Item
          label={fieldLable('gender')}
          name='gender'
        >
          <Select options={[{lable:'male',value: 'male'},{lable:'female',value: 'female'}]}  />
        </Form.Item>

        <Form.Item
          label={fieldLable('email')}
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
         
        >
         <Input type="email" />
        
        </Form.Item>

        <Form.Item
          label={fieldLable('password')}
          name="password"
          initialValue={pass}
          rules={[
            {
              required: true,
            },
          ]}
         
        >
         <Input value="password" />
        
        </Form.Item>

        <Form.Item
          label={fieldLable('role')}
          name='role'
        >
          <Select options={roleOptions}  />
        </Form.Item>

        <Form.Item
          label={fieldLable('block')}
          name='block'
          initialValue={false}
        >
          <Input type="checkbox"/>
          
        </Form.Item>

        <Form.Item
          label={fieldLable('bio')}
          name='bio'
          
        >
          <RichText edit   />
        </Form.Item>

        <Form.Item
          label={fieldLable('scfhs')}
          name="scfhs"
          
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={fieldLable('workplace')}
          name="workplace"
          
          
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={fieldLable('position')}
          name='position'
        >
          <Select options={positionOptions}  />
        </Form.Item>

        <Form.Item
          label={fieldLable('profession')}
          name='profession'
        >
          <Select options={professionOptions}  />
        </Form.Item>
          
      </Form>
     
    </Create>
  );
};

