import { IOrder } from "interfaces";

export function groupData(data, fn,map = i => i) {
  const groups = data.reduce((groups, filed) => {
   const groupName = fn(filed)
    if (!groups[groupName]) {
      groups[groupName] = [];
    }
    groups[groupName].push(map(filed));
    return groups;
  }, {});
  
  // Edit: to add it in the array format instead
  const groupArrays = Object.keys(groups).map((key) => {
    return {
      name:key,
      data: groups[key]
    };
  });
  return groupArrays
}


export const createReport = (name,orders:IOrder[]) => {

  if(!orders) {
    return  {name,orders:0,revenue:0,cancel:0,refund:0}
  }


  const revenue = parseFloat(orders.filter(order => order.paymentStatus === 'paid').reduce((p,a)=> p+a.amount,0).toFixed(2)) 
  
  const cancel = orders.filter(order => order.paymentStatus === 'cancel').length
  
  const refund = orders.filter(order => order.paymentStatus === 'full-refund' || order.paymentStatus === 'partial-refund' ).length
   
  
      return {name,orders:orders.length,revenue,cancel,refund}
    
  
  }


  export function betweenDates(date1String,date2String,data:IOrder[]) {

    const date = new Date(date1String)
    date.setHours(0,0,0)
    const date2 = new Date(date2String)
    date2.setHours(23,59,59,999)
  
    return data.filter(o => {
      let dt = new Date(o.date).getTime()
      
      return dt >= date.getTime() && dt <=date2.getTime()
    })
  
  }


  