import { useList, useMany, useOne } from "@pankod/refine";

export function toPercent(number:number):number{
  
  if(number < 1) return 0

  if(number > 99) return 1



  const percent = number/100

  return parseFloat(percent.toFixed(2)) 

}


export function useOneResource({id = '',resource,map = i => i}) {

 
  const dataQueryResult = useOne({
    resource,
    id,
});




  const props = dataQueryResult.data?.data

  //const elem = records?.map(i => <Element key = {i._id} {...i} /> )
  if(!props) return null

  return map(props)
}

export function useManyResource({resource,map = i =>i,filter= i => i}) {
      
  
  const { data} = useList({
    resource,
  
  });



  const records = data?.data.filter(filter)

  const elem = records?.map(i =>  map(i) )
  

  return elem
}





