import { useState } from "react";
import {
  Edit,
  Form,
  Input,
  Select,
  IResourceComponentsProps,
  useForm,
  useSelect,
  useTranslate,
  AutoComplete,
} from "@pankod/refine";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

import { ICountry,ICity,IVenue} from "interfaces";
import { useManyResource } from "utils";

interface IOptions {
  label: string | React.ReactNode;
  
}



export const VenueEdit: React.FC<IResourceComponentsProps> = () => {
 

  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult } = useForm<IVenue>();

const record = queryResult?.data?.data

  const { selectProps: countrySelectProps } = useSelect<ICity>({
    resource: "location/city",
    defaultValue: queryResult?.data?.data.city._id,
    optionLabel: "name",
    optionValue: "_id",
    
    
  });

  const cities = useManyResource({resource:'location/city',})

  const filterOption = (inputValue, option) =>{

 return   option!.label.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
}

 // const options = cities?.map(c => ({value: c.name}))
 

  return (
    <Edit saveButtonProps={saveButtonProps}>
      <Form {...formProps} layout="vertical"
      
      
      >
        <Form.Item
          label={t("city.fields.name")}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("venue.fields.city")}
          name='city'
          rules={[
            {
              required: true,
            },
          ]}
        >

          
          {/* <AutoComplete options = {options} filterOption={(inputValue, option) =>
      option!.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
    }> */}

     

          <Select  {...countrySelectProps} filterOption={filterOption}  />
         
        
        </Form.Item>

        <Form.Item
          label={'Map'}
          name='mapUrl'
        >

          <Input />
         
        
        </Form.Item>

        <Form.Item
          label={'Booking URL'}
          name='bookingUrl'
        >

          <Input />
         
        
        </Form.Item>
          
      </Form>
    </Edit>
  );
};
