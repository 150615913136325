import {
  List,
  Table,
  TextField,
  useTable,
  IResourceComponentsProps,
  getDefaultSortOrder,
  DateField,
  Space,
  EditButton,
  DeleteButton,
  useMany,
  useSelect,
  TagField,
  FilterDropdown,
  Select,
  ShowButton,
  useTranslate,
  useOne,
  NumberField,
  BooleanField,
  getDefaultFilter,
} from "@pankod/refine";
import { ICity,IVenue,ISubject,ICoupon, IStoreItem, IProduct } from "interfaces";
import { fieldLable } from "./index";



export const StoreItemList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();

  const { tableProps, sorter,filters } = useTable<IProduct>({
    initialSorter: [
      {
        field: "id",
        order: "desc",
      },
    ],
  });

  // export interface ICountry {
  //   _id: string;
  //   name: string;
  //   code: string;
  // }

//   const categoryQueryResult = useOne<ICategory>({
//     resource: "categories",
//     id: "1",
// });


const dataSource = tableProps?.dataSource?.filter(p => p.kind !== 'Course')
 
const props = {...tableProps,dataSource}

  return (
    <List>
      <Table {...props} rowKey="id"  >
       
        <Table.Column
          dataIndex="title"
          
          title={t(fieldLable('name'))}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
          
        />

<Table.Column
          dataIndex="price"
          
          title={t(fieldLable('price'))}
          render={(value) => <TextField value={value} />}
          defaultSortOrder={getDefaultSortOrder("name", sorter)}
          sorter
        />


 

        
        <Table.Column<IStoreItem>
          title={t("table.actions")}
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <EditButton hideText size="small" recordItemId={record._id} />
              <ShowButton hideText size="small" recordItemId={record._id} />
              <DeleteButton hideText size="small" recordItemId={record._id} />
            </Space>
          )}
        />
      </Table>
    </List>
  );
};
