import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const data = [
  {
    name: 'Month 1',
    revenue: 4000,
    orders: 800,
    cancels: 30,
    refunds:5
  },

  {
    name: 'Month 2',
    revenue: 30090,
    orders: 9060,
    cancels: 0,
    refunds:5
  },

  {
    name: 'Month 3',
    revenue: 50038,
    orders: 1000,
    cancels: 30,
    refunds:5
  },

  {
    name: 'Month 4',
    revenue: 2000,
    orders: 3,
    cancels: 0,
    refunds:5
  },

  {
    name: 'Month 5',
    revenue: 8000,
    orders: 50,
    cancels: 0,
    refunds:0
  },

  {
    name: 'Month 6',
    revenue: 50038,
    orders: 1000,
    cancels: 0,
    refunds:0
  },
  
];

export default function Chart({data}) {
  //     resourcestatic demoUrl = 'https://codesandbox.io/s/simple-line-chart-kec3v';

  
    return (
      <ResponsiveContainer width="100%" aspect={3}>
        <LineChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line type="monotone" dataKey="revenue" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="orders" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="cancel" stroke="#8884d8" activeDot={{ r: 8 }} />
          <Line type="monotone" dataKey="refund" stroke="#8884d8" activeDot={{ r: 8 }} />
        </LineChart>
      </ResponsiveContainer>
    );
  }

