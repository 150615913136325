import { useState } from "react";
import {
  Edit,
  Form,
  Input,
  Select,
  IResourceComponentsProps,
  useForm,
  useSelect,
  useTranslate,
  FileField,
  getValueFromEvent,
  ImageField,
  Upload,
  file2Base64,
  useApiUrl,
  UploadFile,
  EmailField,
  
} from "@pankod/refine";
import ReactMarkdown from "react-markdown";
import ReactMde from "react-mde";

import "react-mde/lib/styles/css/react-mde-all.css";

import { ICountry,ICity,IInstitute,ISubject, IInstructor,IUser} from "interfaces";
import httpClient from "httpClient";
import RichText from "components/RichText";

import passGen from 'generate-password'



export const UserEdit: React.FC<IResourceComponentsProps> = () => {
 
  

  const t = useTranslate();

  const { formProps, saveButtonProps, queryResult } = useForm<IUser>();
  const record =  queryResult?.data?.data

  const pass = passGen.generate({
    length: 10,
	numbers: true
  })


  // const { selectProps: countrySelectProps } = useSelect<ISubject>({
  //   resource: "academy/subject",
  //   defaultValue: queryResult?.data?.data.subjects.map(s => s._id),
  //   optionLabel: "name",
  //   optionValue: "_id",
    
  // });

  const image:UploadFile = {
    url:record?.avatar ?? '',
    name:record?.avatar ?? '',
    size:500,
    uid:record?.avatar ?? ''
  }


  const fieldLable = lable => t(`user.fields.${lable}`)
 // ["admin","student",'organizer']

  const roleOptions = [
    {label:'student',value: 'student'},
    {label:'organizer',value: 'organizer'},
    {label:'lms manager',value: 'lms-manager'},
    {label:'admin',value: 'admin'}
  ]

  const positionOptions = [
    {label:'bachelor student',value: 'bachelor-student'},
    {label:'intern',value: 'intern'},
    {label:'graduate',value: 'graduate'},
    {label:'employee',value: 'employee'},
    {label:'master student',value: 'master-student'}
  ]

  const professionOptions = [
    {label:'physiotherapist',value: 'physiotherapist'},
    {label:'occupational therapist',value: 'occupational-therapist'},
    {label:'other',value: 'other'}
  ]
  
  return (
    <Edit  saveButtonProps={saveButtonProps}>
      <Form  {...formProps} layout="vertical"
      
      onFinish={async (values:any) => {
        
      
        console.log('avatar',values.avatar)

        const data = new FormData()

        for (const key in values) {

          if(key === 'avatar' && values?.avatar ) data.append(key,values[key].file)

          else {
            data.append(key,values[key])
          }


         
        }
    
       return formProps.onFinish && formProps.onFinish(data)
   

        
    }}
      
      
      >

      <Form.Item >
                    <Form.Item
                         name="avatar"
                         
                        
                       
                        noStyle
                    >
                        <Upload.Dragger
                        defaultFileList={[image]}    
                       accept="image/png, image/jpeg"
                       listType="picture-card"
                           
                            maxCount={1}
                            
                            
                            beforeUpload={() => false}
                        >
                            <p className="ant-upload-text">
                                Drag & drop a file in this area
                            </p>
                        </Upload.Dragger>
                    </Form.Item>
                </Form.Item>

      

        <Form.Item
          label={fieldLable('name')}
          name="name"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>

        

        

        <Form.Item
          label={fieldLable('gender')}
          name='subjects'
        >
          <Select options={[{lable:'male',value: 'male'},{lable:'female',value: 'female'}]}  />
        </Form.Item>

        <Form.Item
          label={fieldLable('email')}
          name="email"
          rules={[
            {
              required: true,
            },
          ]}
         
        >
         <Input type="email" />
        
        </Form.Item>

        <Form.Item
          label={fieldLable('role')}
          name='role'
        >
          <Select options={roleOptions}  />
        </Form.Item>

        <Form.Item
          label={fieldLable('block')}
          name='block'
        >
          <Input type="checkbox"/>
          
        </Form.Item>

        <Form.Item
          label={fieldLable('bio')}
          name='bio'
          
        >
          <RichText edit   />
        </Form.Item>

        <Form.Item
          label={fieldLable('scfhs')}
          name="scfhs"
          
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={fieldLable('workplace')}
          name="workplace"
          
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={fieldLable('position')}
          name='position'
        >
          <Select options={positionOptions}  />
        </Form.Item>

        <Form.Item
          label={fieldLable('profession')}
          name='profession'
        >
          <Select options={professionOptions}  />
        </Form.Item>
          
      </Form>
     
    </Edit>
  );
};


{/* <Show isLoading={isLoading}>
  <div>
  <Title level={3}>account info</Title>
  <Title level={5}>{fieldLable('avatar')}</Title>
<Image src = {record?.avatar} />
      <Title level={5}>{fieldLable('name')}</Title>
      <Text>{record?.name}</Text>

      <Title level={5}>{fieldLable('gender')}</Title>
      <Text>{record?.gender ?? 'not set'}</Text>


      <Title level={5}>{fieldLable('email')}</Title>
      <Text>{record?.email}</Text>

      <Title level={5}>{fieldLable('role')}</Title>
      <Text>{record?.role}</Text>

      <Title level={5}>{fieldLable('join')}</Title>
      <DateField value = {record?.date}/>

      <Title level={5}>{fieldLable('active')}</Title>
      <BooleanField value = {!record?.block}/>

      <Title level={5}>{fieldLable('bio')}</Title>
      <RichText value = {record?.bio}/>

  </div>

  <div>
  <Title level={3}>student info</Title>
  </div>
  <Title level={5}>{fieldLable('scfhs')}</Title>
      <Text>{record?.scfhs}</Text>
      <Title level={5}>{fieldLable('profession')}</Title>
      <Text>{record?.profession}</Text>
      <Title level={5}>{fieldLable('position')}</Title>
      <Text>{record?.position}</Text>
      <Title level={5}>{fieldLable('workplace')}</Title>
      <Text>{record?.workplace}</Text>
     

      
    </Show> */}
